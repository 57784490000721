import axios from "axios";

/**
    * @function getFeaturedCategories
    * @param
    * @description This function is used to list the featured categories
*/
export const getFeaturedCategories = async ({highlight = ''}) => {
    try {
        const result = await axios.get(`admin/category-list?${highlight && "highlight=" + highlight}`);
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function searchCategory
    * @param { searchQuery }
    * @description This function is used to list the featured categories
*/
export const searchCategory = async ({ searchQuery = "" }) => {
    try {
        const result = await axios.get(`admin/category-list?${searchQuery && "search=" + searchQuery}`);
        return result;
    } catch (error) {
        return error;
    }
};
