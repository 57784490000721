/* eslint-disable jsx-a11y/img-redundant-alt */

//importing images
import { Link } from "react-router-dom";
// import avatar from "../../../../assets/images/FeaturedBestInstructor/avatar.svg";

//importing styles
import "../../../../assets/scss/components/card/FeaturedCoachCard/FeaturedCoachCard.scss";
// import avatar from "../../../../assets/images/Cards/HorizentalCard/Avatar.svg";
import avatar from "../../../../assets/images/Auth/avatar2.svg";

import SkeletonBox from "../../../Skeleton/SkeletonBox";
import Skeleton from "react-loading-skeleton";
import Image from "../../image";
//

const FeaturedCoachCard = ({ cardDetail }) => {
     const stripHtmlTags = (html) => {
          const doc = new DOMParser().parseFromString(html, 'text/html');
          return doc.body.textContent || "";
        };
     return (
          <div className="card-main-container card-dimention m-2 w-100">
               <div className="card p-2">
                    {cardDetail
                    ?
                         <Image className="card-img-top"
                              src={cardDetail?.avatar}  
                              style={{ width: "100%", height: "260px" }}
                              defaultImage={avatar}
                              alt='coach'
                         />:
                         // <SkeletonBox>
                              <Skeleton width={250} height={250}/>
                         // </SkeletonBox> 
                    }
                    <div className="card-body max-h-p p-2 my-2 d-flex justify-content-between ">
                        { cardDetail?<>
                              <Link className="text-decoration-none" to={`/coach/${cardDetail?.id}`}>
                                   <p className="card-text m-0  fw-bold m-1 w-100">
                                        {cardDetail?.full_name}
                                   </p>
                              </Link>
                              {/* <p className="card-text m-0 text-secondary  m-1  text-end overflow-hidden">
                                   {stripHtmlTags(cardDetail?.expertise)}
                              </p> */}
                         </>:
                         <SkeletonBox>
                              <Skeleton height={30}/>
                         </SkeletonBox>
                         
                         }
                    </div>
               </div>
          </div>
     );
};

export default FeaturedCoachCard;
