import React from 'react';
import Account from '../../account/Account';
import Dropdown from '../../../components/common/dropdown/Dropdown';


//css
import '../../../assets/scss/pages/learnAndEarn/rewardWithdrawal.scss'
import Button from '../../../components/common/button/Button';


const RewardWithdrawal=()=> {


    return (
         <Account>
            <div className='tran-container d-flex  justify-content-center'>
                <div className='main-reward-container'>
                    <h4 className='main-title fw-bold'>ReSkills Reward Points Withdrawal</h4>
                    <small>
                        After you fill out this withdrawal request form, we will contact you to go over details and availability before the order is completed. If you have any further questions about the withdrawal process, you may contact us at info@reskills.com.
                    </small>
                    {/* <div className='shadow-sm rounded my-4 border border-light  flex-column p-3 '>
                        
                        <div className='fw-bold mx-2 '>ReSkills Account Email / Username*</div>
                        <div className='m-2 '>
                            <small className='fw-semibold'>Email / Username*</small>
                            <input
                                className="form-control my-2"
                                type="text"
                                placeholder="Enter Here"
                                       
                            />
                        </div>
                    </div> */}
                    <div className='shadow-sm rounded my-4 border border-light  flex-column p-3 '>
                        
                        <div className='fw-bold mx-2 '>Withdrawal Type and Points</div>
                        <div className='m-2 '>
                            <p className='fs-6'>Please indicate how you would like to withdraw your points. You are required to have a minimum of 30 points in your account before you can withdraw. Any withdrawal request for an amount more than what you have in your account automatically be rejected and will not be processed.</p>
                            <div className=''>
                                <p className='fw-semibold my-2 fs-6'>How would you like to withdraw your reward points? *</p>
                                <div className="dropdown-main-container">
                                    <select
                                        className="dropdown-itemMenu form-control"
                                    >
                                        <option className="dropdown-items">
                                            Select here
                                        </option>
                                            <option
                                            className="dropdown-items"
                                            >
                                            select
                                            </option>
                                    </select>
                                    </div>
                            </div>
                            <div className='my-4'>
                                <p className='fw-semibold my-2 fs-6'>How many points would you like to withdraw ?</p>
                                <input
                                    className="form-control my-2"
                                    type="text"
                                    placeholder="Enter Here"
                                        
                                />
                            </div>
                            
                            
                        </div>
                    </div>
                    {/* <div className='shadow-sm rounded my-4 border border-light  flex-column p-3 '>
                        
                        <div className='fw-bold mx-2 '>Preferred contact method*</div>
                        <div className='m-2 '>
                        <div className="form-check d-flex gap-2 ">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                name=""
                            />
                            <p className="m-0 fs-6">Phone</p>
                        </div>
                        <div className="form-check d-flex gap-2 ">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                name=""
                            />
                            <p className="m-0 fs-6">Email</p>
                        </div>
                        </div>
                    </div> */}
                    <div className="form-check d-flex gap-2 ">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckChecked"
                                name=""
                            />
                            <p className="m-0 fs-6">I have read and agreed to the terms and condition.</p>
                    </div>
                    <div className='my-3 mb-5 max-width-reward'>
                    <Button
                        name='Next'
                    />
                    </div>
                </div>
            </div>
        </Account>
    );
}

export default RewardWithdrawal;