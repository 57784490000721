import { Link, useLocation } from "react-router-dom"
import { IoIosClose, IoIosCheckmark, IoIosArrowForward, IoIosRadioButtonOn, IoIosRadioButtonOff } from "react-icons/io";
import { useSelector } from "react-redux";
import moment from "moment";
import classNames from "classnames";
import { useState } from "react";
import { toast } from "react-toastify";

import { Tooltip, OverlayTrigger, } from 'react-bootstrap';

//import react rating
import { Rating } from "react-simple-star-rating";

//importing images
import ratingIcon from "../../../../assets/images/Rating/ratingIcon.svg";
import dateIcon from "../../../../assets/images/Cards/HorizentalCard/dateIcon.svg";
import timerIcon from "../../../../assets/images/Cards/HorizentalCard/timerIcon.svg";
import wwwIcon from "../../../../assets/images/Navbar/wwwIcon.svg";
import avatar from "../../../../assets/images/Auth/avatar2.svg";
import arrow from "../../../../assets/images/Cards/VerticalCard/cardArrow.svg";
import alternateBanner from "../.../../../../../assets/images/Cards/VerticalCard/altClassBanner.svg";

//importing styles
import "../../../../assets/scss/components/card/verticalCard/verticalCard.scss";
import { selectUserDetails } from "../../../../redux/userSlice";




//import
import Skeleton from "react-loading-skeleton";
import SkeletonBox from "../../../Skeleton/SkeletonBox";
import Image from "../../image";
import { selectLearnAndEarnSubscriptionDetail } from "../../../../redux/learnAndEarnSubscriptionSlice";

const VerticalCard = ({ cardContent, cardType, certificatStatus, Loading ,leave_time , join_time ,pageSlug, ...props  }) => {
    
    const location = useLocation()
    const userDetail = useSelector(selectUserDetails);
    // const [hasError, setHasError] = useState(false);
    const dt = moment('2024-07-29');
    const currentDate = new Date();
    const currentDateString = moment
        .parseZone(currentDate)
        .local()
        .format('YYYYMMDD');

    const classStartDate = moment
        .parseZone(cardContent?.start_date_time)
        .local()
        .format('YYYYMMDD');

    const currenttTime = moment
        .parseZone(currentDate)
        .local()
        .format()
        .replace(/-/g, "");

    const classEndTime = moment
        .parseZone(cardContent?.end_date_time)
        .local()
        .format()
        .replace(/-/g, "");


    const subscriptionDetail = useSelector(selectLearnAndEarnSubscriptionDetail)
    




        const download = () => {

            

            let url = cardContent?.certificates[0]?.pdf;
           
            if(subscriptionDetail?.is_enpl){
                toast.error("Unable to download the certificate due to this account’s ENPL status. If you’re ready to upgrade your account and unlock this feature, submit a request to support@reskills.com.");
              }else{
                
                if (url) {
                // Modify the URL if "public/users" is not present
                if (!url?.includes("public/users")) {
                    url = url?.replace("public/", "public/micro-cert/");
                }

            
                fetch(url).then(response => {
                    response.blob().then(blob => {
                    const fileURL = window.URL.createObjectURL(blob);
                    const alink = document?.createElement('a');
                    alink.href = fileURL;
                    alink.download = url?.split("/")[url?.split("/")?.length - 1];
                    alink.click();
                    });
                });
                }
            }
          };
    
    return (
        <div className="verticalCard-main-container ">
            <div className={`card border-0 shadow-sm mb-4 min-height-card`}>
                <Link to={`/class/${cardContent.slug}`}>
                    {cardContent ?
                        <img
                            className="card-img-top"
                            src={
                                cardContent?.images
                                    ? cardContent?.images[0]?.image
                                    : cardContent?.thumb_image ? cardContent?.thumb_image : alternateBanner
                            }
                            alt="Card cap"
                        /> :
                        <SkeletonBox>
                            <Skeleton height={200} />
                        </SkeletonBox>
                    }
                </Link>
                <div className="card-body">
                    <Link className="text-dark text-decoration-none" to={`/class/${cardContent.slug}`}>
                        <div className="d-flex align-items-center mb-3">
                            {cardContent ?
                                <>
                                    <h6 className="truncate-2-lines flex-grow-1 fw-bold me-3 mb-0">{cardContent?.title}</h6>
                                    <img
                                        src={arrow}
                                        alt="arrow"
                                    />
                                </> :
                                <SkeletonBox>
                                    <Skeleton height={30} />
                                </SkeletonBox>
                            }

                        </div>
                    </Link>
                    <div className="d-flex justify-content-between">
                        {cardContent && !(cardContent?.class_type?.name?.toLowerCase() === "onthego") && ((cardContent?.class_type?.name?.toLowerCase() === "onthego")|| !((cardContent?.class_type?.name?.toLowerCase() === "onstage"|| cardContent?.class_type?.name?.toLowerCase() === "onference") && classEndTime < currenttTime)) ?
                            <>
                                <div className="d-flex align-items-centre gap-1">
                                    <img src={dateIcon} alt="dateIcon" />
                                    <span className={`${classStartDate > currentDateString || (classStartDate === currentDateString && classEndTime > currenttTime) ? 'text-primary fw-semibold' : 'card-text'}`}>
                                        {cardContent?.start_date_time &&
                                            moment(cardContent?.start_date_time).format(
                                                "ddd, Do MMM YYYY"
                                            )}
                                    </span>
                                </div>
                                <div className="d-flex align-items-centre gap-1">
                                    <img src={timerIcon} alt="dateIcon" />
                                    <span className={` ${classStartDate > currentDateString || (classStartDate === currentDateString && classEndTime > currenttTime) ? 'text-primary fw-semibold' : 'card-text'}`}>
                                        {cardContent?.start_date_time &&

                                            moment
                                                .parseZone(cardContent?.start_date_time)
                                                .local()
                                                .format("hh:mm A")}{" "}
                                    </span>
                                </div>
                            </>:
                            <div className="my-3">

                            </div> 
                        }

                    </div>
                    <div className="d-flex align-items-center gap-1 mt-2">
                    {cardContent ? (
                        <div className="d-flex justify-content-between align-items-center w-100">
                        <div className="d-flex align-items-center gap-2">
                            <img src={wwwIcon} alt="dateIcon" />
                            
                            <span className="card-text">{cardContent?.language?.name}</span>
                        </div>
                        {
                            cardContent?.class_type?.name &&
                            pageSlug?.toLowerCase()==='todays-classes' && (
                                <div className="text-white fw-semibold px-2 rounded-radius">
                                {cardContent?.class_type?.name}
                                </div>
                            )
                        }
                        </div>
                    ) : (
                        <SkeletonBox>
                        <Skeleton />
                        </SkeletonBox>
                    )}
                    </div>


                    <div className="d-flex justify-content-between align-items-center mt-3 ">
                        {cardContent?.coaches?.map((item) => {
                            return (
                                <div className="d-flex gap-2 align-items-center" key={`coach-${item?.id}`}>
                                    <div className="rounded-circle overflow-hidden">
                                        <Image
                                            src={item?.avatar}
                                            alt="profile"
                                            style={{ width: "45px", height: "45px" }}
                                            defaultImage={avatar}
                                        />
                                        {/* <img
                                            src={item?.avatar ? item?.avatar : avatar}
                                            alt="profile"
                                            style={{ width: "45px", height: "45px" }}

                                        /> */}

                                    </div>
                                    
                                    <div>
                                        <Link className="text-dark text-decoration-none" to={`/coach/${item?.id}`}>
                                            <p className="mb-0 fw-bold">{item?.full_name || <Skeleton />}</p>
                                        </Link>
                                        {/* {cardType !== "onFerenceCard" && ( */}
                                        {cardContent ?
                                            <Rating
                                                initialValue={cardContent?.average_rating}
                                                value={cardContent?.average_rating}
                                                customIcons={ratingIcon}
                                                readonly={true}
                                                size={20}
                                                allowFraction={true}
                                            /> :
                                            <Skeleton />}
                                        {/* )} */}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {/* {cardType === "onStageCard" && (
                        <Link to={`/class/${cardContent.slug}`} className='text-decoration-none'>
                            <div className="mt-3 d-flex gap-2 align-item-centre">
                                <p className="text-primary m-0">Know More</p>
                                <img src={knowMoreIcon} alt="icon" />
                            </div>
                        </Link>
                    )} */}
                    {location?.pathname === "/my-learning" && props?.tab === "previous-learnings" && 
                        <div className="my-2">
                            <div className="d-flex justify-content-between align-items-center mt-3 ">
                            <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Attendance
                                            </Tooltip>
                                        }
                                    >
                                <div className={classNames("rounded-pill", {
                                    'bg-primary': ((props?.joined && join_time !== null && leave_time !== null && cardContent?.meets_duration_requirement !== false) ||( moment(cardContent?.start_date_time)?.isBefore(dt) && cardContent?.old_class_id && cardContent?.certificates?.length > 0)||( (cardContent?.class_type?.name?.toLowerCase() !== "onlive" ) && join_time !== null && leave_time !== null) || ( cardContent?.class_type?.name?.toLowerCase() !== "onlive" && ((moment(cardContent?.start_date_time)?.isBefore(dt) && cardContent?.old_class_id && cardContent?.certificates?.length > 0)||(cardContent?.certificates?.length > 0 &&cardContent?.reviews?.some(item => item?.user_id === userDetail?.id) && join_time !== null && leave_time !== null ) ))) ,
                                    'bg-secondary': ((join_time === null && leave_time === null) || ( moment(cardContent?.start_date_time)?.isBefore(dt) && cardContent?.old_class_id && cardContent?.certificates?.length <= 0) || (cardContent?.meets_duration_requirement === false)),
                                    'bg-danger': (!props?.joined) ||( cardContent?.meets_duration_requirement === false && join_time !== null && leave_time !== null),
                                    'w-25': cardContent?.class_type?.name?.toLowerCase() === "onlive",
                                    'w-50': cardContent?.class_type?.name?.toLowerCase() !== "onlive"
                                })} style={{
                                    height: "10px"
                                }}>
                                </div>
                                </OverlayTrigger>
                                {(join_time === null && leave_time === null)?
                                    <IoIosRadioButtonOff className="text-secondary  bg-secondary bg-opacity-20 rounded-circle mx-1" size={24} />

                                :((((props?.joined && join_time !== null && leave_time !== null && cardContent?.meets_duration_requirement === true) ||( cardContent?.class_type?.name?.toLowerCase() !== "onlive") || ( moment(cardContent?.start_date_time)?.isBefore(dt) && cardContent?.old_class_id && cardContent?.certificates?.length > 0) || ( cardContent?.class_type?.name?.toLowerCase() !== "onlive" && ((moment(cardContent?.start_date_time)?.isBefore(dt) && cardContent?.old_class_id && cardContent?.certificates?.length > 0)||(cardContent?.certificates?.length > 0 &&cardContent?.reviews?.some(item => item?.user_id === userDetail?.id) ) )))) ?
                                    <IoIosCheckmark className="text-primary bg-purple bg-opacity-20 rounded-circle mx-1" size={24} />
                                    :
                                    <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="tooltip-top">
                                        Did not meet attendance duration requirement 
                                      </Tooltip>
                                    }
                                  >
                                    <span>
                                      <IoIosClose className="text-danger bg-danger bg-opacity-20 rounded-circle mx-1" size={24} />
                                    </span>
                                  </OverlayTrigger>
                                    )
                                }
                                <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Rate & Review
                                            </Tooltip>
                                        }
                                    >
                                <div className={classNames("rounded-pill", {
                                    'bg-primary': (cardContent?.reviews?.some(item => item?.user_id === userDetail?.id))||( moment(cardContent?.start_date_time)?.isBefore(dt) && cardContent?.old_class_id && cardContent?.certificates?.length > 0),
                                    'bg-secondary': !cardContent?.reviews?.some(item => item?.user_id === userDetail?.id) || ( moment(cardContent?.start_date_time)?.isBefore(dt) && cardContent?.old_class_id && cardContent?.certificates?.length <= 0),
                                    'w-25': cardContent?.class_type?.name?.toLowerCase() === "onlive",
                                    'w-50': cardContent?.class_type?.name?.toLowerCase() !== "onlive"
                                })} style={{
                                    height: "10px"
                                }}>
                                </div>

                                </OverlayTrigger>
                                {cardContent?.reviews?.some(item => item?.user_id === userDetail?.id) ?

                                    <IoIosCheckmark className="text-primary bg-purple bg-opacity-20 rounded-circle mx-1" size={24} />
                                    :
                                    // <IoIosClose className="text-danger bg-danger bg-opacity-20 rounded-circle mx-1" size={24} />
                                    <IoIosRadioButtonOff className="text-secondary  bg-secondary bg-opacity-20 rounded-circle mx-1" size={24} />

                                }
                                
                                {cardContent?.class_type?.name?.toLowerCase() === "onlive" && <>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id="tooltip-top">
                                                Assessment 
                                            </Tooltip>
                                        }
                                    >
                                    <div className={classNames("w-25 rounded-pill", {
                                        'bg-primary': (cardContent?.user_assessment_submitted && cardContent?.user_assessment_result?.length > 0 && (cardContent?.user_assessment_result[0]?.retry_attempt !== 1 ||(cardContent?.user_assessment_result[0]?.retry_attempt === 1 && cardContent?.user_assessment_result[0]?.status === 1)) && cardContent?.user_assessment_result[0]?.status !== 0) || ( moment(cardContent?.start_date_time)?.isBefore(dt) && cardContent?.old_class_id && cardContent?.certificates?.length > 0),
                                        'bg-secondary': !cardContent?.user_assessment_submitted || ( moment(cardContent?.start_date_time)?.isBefore(dt) && cardContent?.old_class_id && cardContent?.certificates?.length <= 0),
                                        'bg-danger': cardContent?.user_assessment_result?.length > 0 && (cardContent?.user_assessment_result[0]?.retry_attempt === 1 || cardContent?.user_assessment_result[0]?.retry_attempt === 0) && cardContent?.user_assessment_result[0]?.status === 0
                                    })} style={{
                                        height: "10px"
                                    }}>
                                    </div>
                                    </OverlayTrigger>
                                    {!cardContent?.user_assessment_submitted?
                                        <IoIosRadioButtonOff className="text-secondary  bg-secondary bg-opacity-20 rounded-circle mx-1" size={24} />
                                    :(cardContent?.user_assessment_submitted && cardContent?.user_assessment_result?.length > 0 && ((cardContent?.user_assessment_result[0]?.retry_attempt !== 1 && cardContent?.user_assessment_result[0]?.status === 0) || ( cardContent?.user_assessment_result[0]?.status === 1)) && cardContent?.user_assessment_result[0]?.status !== 0 ?
                                        <IoIosCheckmark className="text-primary bg-purple bg-opacity-20 rounded-circle ms-1" size={24} />
                                        :
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="tooltip-top">
                                                    Did not pass assessment 
                                                </Tooltip>
                                            }
                                            >
                                            <span>
                                                <IoIosClose className="text-danger bg-danger bg-opacity-20 rounded-circle mx-1" size={24} />
                                            </span>
                                            </OverlayTrigger>
                                        
                                    )
                                    }
                                </>
                                }
                            </div>
                            { 
                            
                            ( moment(cardContent?.start_date_time)?.isBefore(dt) && cardContent?.old_class_id && cardContent?.certificates?.length <= 0)? 
                                <span className="text-primary mb-0">
                                    Attended
                                </span>
                            :
                            ( moment(cardContent?.start_date_time)?.isBefore(dt) && cardContent?.old_class_id && cardContent?.certificates?.length > 0) ?
                            
                            <span className="text-primary mb-0 cursor-pointer" onClick={download}>
                                Download Certificate
                                
                            </span>
                            :
                            ( cardContent?.class_type?.name?.toLowerCase() !== "onlive" && ((moment(cardContent?.start_date_time)?.isBefore(dt) && cardContent?.old_class_id && cardContent?.certificates?.length > 0)||(cardContent?.certificates?.length > 0 &&cardContent?.reviews?.some(item => item?.user_id === userDetail?.id) ) )) ?
                            
                           ((join_time !== null && leave_time !== null)?
                             <span className="text-primary mb-0 cursor-pointer" onClick={download}>
                                Download Certificate
                                
                            </span>:
                            <span className="text-primary mb-0">
                                Pending Attendance Verification  
                            </span>
                            
                        )
                            :

                            ((cardContent?.meets_duration_requirement === false &&  join_time !== null && leave_time !== null && cardContent?.reviews?.some(item => item?.user_id === userDetail?.id) && (cardContent?.user_assessment_result[0]?.status === 1 || cardContent?.user_assessment_result[0]?.status === 0) )   ) ?
                                <span className="text-primary mb-0">
                                    Attended 
                                </span>
                                :
                            (cardContent?.meets_duration_requirement === false && join_time === null && leave_time=== null && cardContent?.reviews?.some(item => item?.user_id === userDetail?.id) &&cardContent?.class_type?.name?.toLowerCase()!=="onlive" ) ?
                                                   (cardContent?.user_assessment_result[0]?.status === 0 ? 
                                                    <span className="text-primary mb-0">
                                                        Attended
                                                    </span>
                                                   :<span className="text-primary mb-0">
                                                        Pending Attendance Verification
                                                        
                                                    </span>)
                                                :
                                                (cardContent?.meets_duration_requirement === false && join_time === null && leave_time=== null && cardContent?.reviews?.some(item => item?.user_id === userDetail?.id) &&((cardContent?.user_assessment_result[0]?.status === 0) || (cardContent?.user_assessment_result[0]?.status === 1)) && (cardContent?.class_type?.name?.toLowerCase()==="onlive") ) ?
                                                (cardContent?.user_assessment_result[0]?.status === 0 ? 
                                                    <span className="text-primary mb-0">
                                                        Attended
                                                    </span>
                                                   :<span className="text-primary mb-0">
                                                        Pending Attendance Verification
                                                        
                                                    </span>):
                            
                            ((cardContent?.user_assessment_submitted && cardContent?.class_type?.name?.toLowerCase() === "onlive" && cardContent?.user_assessment_result?.length > 0  && cardContent?.user_assessment_result[0]?.status !== 0 && cardContent?.reviews?.some(item => item?.user_id === userDetail?.id) && cardContent?.meets_duration_requirement === true) || (cardContent?.class_type?.name?.toLowerCase() !== "onlive" && cardContent?.reviews?.some(item => item?.user_id === userDetail?.id) && cardContent?.meets_duration_requirement === true)) ?
                                <>
                                    {certificatStatus ?
                                        <Link
                                            className="text-decoration-none mt-1"
                                        // to={certificatStatus ? '/account/certificate' : ''}
                                        >
                                            <span className="text-primary mb-0" onClick={download}>
                                                Download Certificate
                                                
                                            </span>
                                        </Link>
                                        :
                                        (cardContent?.user_assessment_result[0]?.status === 0 ? 
                                            <span className="text-primary mb-0">
                                                Attended
                                            </span>
                                           :<span className="text-primary mb-0">
                                                Pending Attendance Verification
                                                
                                            </span>)
                                    }
                                </>
                                :
                                
                                cardContent?.user_assessment_submitted && cardContent?.user_assessment_result?.length > 0 && cardContent?.user_assessment_result[0]?.retry_attempt === 1 && cardContent?.user_assessment_result[0]?.status === 0 ?
                                // <Link className="text-decoration-none mt-1" >

                                    <span className="text-primary">
                                        Attended
                                        

                                    </span>
                                // </Link>
                                    :
                                    ((!cardContent?.user_assessment_submitted && cardContent?.reviews?.some(item => item?.user_id === userDetail?.id) && cardContent?.class_type?.name?.toLowerCase() === "onlive") || (cardContent?.user_assessment_submitted && cardContent?.user_assessment_result?.length > 0 && cardContent?.user_assessment_result[0]?.retry_attempt === 0 && cardContent?.user_assessment_result[0]?.status === 0)) ?
                                        <Link className="text-decoration-none mt-1" to={`/assessment/${cardContent?.slug}`}>
                                            <span className="text-primary mb-0">
                                                Continue to Assessment
                                                <IoIosArrowForward />
                                            </span>
                                        </Link>
                                        :
                                        (cardContent?.reviews?.some(item => item?.user_id === userDetail?.id) && cardContent?.meets_duration_requirement === false) ?
                                            <span className="text-primary mb-0">
                                                Attended

                                                
                                            </span>
                                            :
                                            !cardContent?.reviews?.some(item => item?.user_id === userDetail?.id) ?
                                                <Link className="text-decoration-none mt-1" to={ currenttTime >classEndTime ?`/class/${cardContent?.slug}`:''}>
                                                    <span className={currenttTime >classEndTime ?"text-primary mb-0":"text-secondary mb-0"}>
                                                        Rate & Review
                                                        <IoIosArrowForward />
                                                    </span>
                                                </Link>
                                                :
                                                <></>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default VerticalCard;
