function SkeletonBox({ children }) {
    return (
      <div
        style={{
          display: 'block',
        //   lineHeight: 2,
          width: '100%',
        }}
      >
        {children}
      </div>
    );
  }

export default SkeletonBox;