import { useEffect } from "react";
import { useDispatch } from "react-redux";

// Custom Component
import Navbar from "../../components/common/navbar/Navbar";
import Footer from "../../components/common/footer/Footer";
import Banner from "../../components/Banner/Banner";
import Features from "../../components/Features/Features";
import LiveCourse from "../../components/Course/LiveCourse/LiveCourse";
import OnLiveClassListing from "../../components/Course/OnLiveClassListing/OnLiveClassListing";
import OnStageClassListing from "../../components/Course/OnStageClassListing/OnStageClassListing";
import OnFerenceClassListing from "../../components/Course/OnFerenceClassListing/OnFerenceClassListing";
import FeaturedWhyUsSection from "../../components/FeaturedWhyUsSection/FeaturedWhyUsSection";
import FeaturedCategory from "../../components/FeaturedCategory/FeaturedCategory";
import LearnAnytimeClassListing from "../../components/Course/LearnAnytimeClassListing/LearnAnytimeClassListing";
import FeaturedBestInstructor from "../../components/FeaturedBestInstructor/FeaturedBestInstructor";
import Testimonial from "../../components/Testimonial/Testimonial";

// API Service
import { getDashboardStats, getReviews } from "../../services/dashboard.service";
import { setStatsList } from "../../redux/statsService";

// Redux-Functions
import { setReviewList } from "../../redux/reviewSlice";
//style
import '../../assets/scss/pages/home/home.scss'

const Home = () => {
    const dispatch = useDispatch();

    const fetchStats = async () => {
        try {
            const result = await getDashboardStats();
            dispatch(setStatsList(result?.data?.dashboardStats));
        } catch (error) {
            console.log(error);
        }
    };

    const fetchAllReviews = async () => {
        try {
            const result = await getReviews();
            dispatch(setReviewList(result?.data?.writeUps));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchStats();
        fetchAllReviews();
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            
            <div className="inner-container">
                
                <div className="slider-container">
                    <Navbar />
                    <Banner FeaturedCardDetail="12" />
                </div>
                
            </div>
        <div className="home-main-container custom-container max-w-home ">
           
            <div className="">
                <Features />
                <LiveCourse />
                <OnLiveClassListing />
                <OnStageClassListing />
                <OnFerenceClassListing />
                <FeaturedCategory />
                <FeaturedWhyUsSection />
                <LearnAnytimeClassListing />
                <FeaturedBestInstructor />
                <div className="slider-container">
                    <Testimonial />
                </div>
            </div>
        </div>
        <Footer />

        </div>
    );
}

export default Home;
