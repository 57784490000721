import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import VerticalCard from "../cards/courseCards/VerticalCard";
import NoData from "../noData/NoData";
import {
    getBookmarkListService,
    getJoinedClassListService,
    getRegisteredClassListService
} from "../../../services/learning.service";
import {
    setRegisteredClassList,
    selectRegisteredClassList,
    setJoinedClassList,
    selectJoinedClassList,
    setBookmarkClassList,
    selectBookmarkClassList
} from "../../../redux/classSlice";
import "../../../assets/scss/components/paginate/paginate.scss";

function Items({ cardContent, tabKey }) {
    return (
        <>
            {cardContent?.length > 0 ? (
                <div className="d-flex flex-wrap gap-5 card-container">
                    {cardContent?.map((item) => (
                        <div key={item.id}>
                            <VerticalCard
                                cardContent={item?.class}
                                joined={item?.is_joined}
                                tab={tabKey}
                                certificatStatus={item?.certificate_status}
                                join_time={item?.join_time}
                                leave_time={item?.leave_time}
                            />
                        </div>
                    ))}
                </div>
            ) : (
                <div className="d-flex justify-content-center w-100">
                    <NoData page="my-learning" />
                </div>
            )}
        </>
    );
}

const MyLearningPaginate = ({ tabKey, activeBadge, selectedCategory ,searchQuery }) => {
    const dispatch = useDispatch();

    // Determine which class list to use based on the tabKey
    const classes = useSelector(tabKey === "previous-learnings" ? selectJoinedClassList :
        tabKey === "my-registrations" ? selectRegisteredClassList :
            selectBookmarkClassList);

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 9;
    const [totalItem, setTotalItem] = useState(0);

    const fetchClasses = async (page) => {
        try {
            let result;
            if (tabKey === "previous-learnings") {
                result = await getJoinedClassListService({
                    perPageItem: itemsPerPage,
                    page: page + 1,
                    category: selectedCategory,
                    tab: activeBadge,
                    searchQuery
                });
                dispatch(setJoinedClassList(result.data?.joined_classes || []));
                setTotalItem(result.data?.joined_classes_count || 1);
            } else if (tabKey === "my-registrations") {
                result = await getRegisteredClassListService({
                    perPageItem: 9,
                    page: page + 1,
                    category: selectedCategory,
                    tab: activeBadge,
                    searchQuery
                });
                dispatch(setRegisteredClassList(result.data?.registered_classes || []));
                setTotalItem(result?.data?.registered_classes_count || 1);
            } else if (tabKey === "my-playlist") {
                result = await getBookmarkListService({
                    perPageItem: itemsPerPage,
                    page: page + 1,
                    category: selectedCategory,
                    searchQuery
                });
                dispatch(setBookmarkClassList(result?.data?.bookmarked_classes || []));
                setTotalItem(result?.data?.bookmarked_classes_count || 1);
            }
        } catch (error) {
            console.error("Error fetching classes:", error);
        }
    };

    const handlePageClick = async (event) => {
        const newPage = event.selected;
        setCurrentPage(newPage);
        await fetchClasses(newPage);
    };

    useEffect(() => {
        fetchClasses(currentPage);
    }, [tabKey, selectedCategory, activeBadge, currentPage,searchQuery]);

    const pageCount = Math.ceil(Number(totalItem) / itemsPerPage);


    return (
        <>
            <Items cardContent={classes} tabKey={tabKey} />
            <div className="d-flex justify-content-center w-100">
                <ReactPaginate
                    breakLabel="..."
                    nextLabel={currentPage === pageCount - 1 ? null : "Next"}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={currentPage === 0 ? null : "Previous"}
                    renderOnZeroPageCount={null}
                    className="paginationComponent"
                    containerClassName="pagination"
                    previousClassName={currentPage === 0 ? "previous hide-arrow" : "previous"}
                    nextClassName={currentPage === pageCount - 1 ? "hide-next-arrow" : "next"}
                />
            </div>
        </>
    );
};

export default MyLearningPaginate;
