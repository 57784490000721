import { useEffect, useState } from "react";
import Button from "../common/button/Button";
import FeaturedProfileCard from "../common/cards/FeaturedProfileCard/FeaturedProfileCard";

//importing styles
import "../../assets/scss/components/featuredBestInstructor/featuredBestInstructor.scss";

//importing services
import { getFeaturedCoaches } from "../../services/coach.service";
import { Link } from "react-router-dom";

const FeaturedBestInstructor = () => {
  const [featuredCoaches, setFeaturedCoaches] = useState();

  useEffect(() => {
    const fetchFeaturedCoaches = async () => {
      const result = await getFeaturedCoaches({
        trending: true,
        perPageItem:6,
        page:1
      });
      setFeaturedCoaches(result?.data?.coaches?.data);
    };
    fetchFeaturedCoaches();
  }, []);

  const trendingCoaches = featuredCoaches?.slice(0, 6);
  return (
    <div className="FeaturedBestInstructor-main ">
      <div className="FeaturedBestInstructor-inner-container d-flex align-items-start justify-content-between w-100 ">
        <div className="FeaturedBestInstructor-main-left-container col-4">
          <h2 className="fw-bold mb-4">
            <span className="text-primary">Expert Coaches</span>
          </h2>
          <p className="text-secondary mb-5">
            At ReSkills, we are committed to assemble a diverse team of expert coaches to deliver outstanding courses.
          </p>
          <div className="btn-container mb-5">
            <Link to={'explore-all-coaches'}>
              <Button btnType="whiteButton" name="Explore All Coaches" />
            </Link>
          </div>
        </div>
        <div className="FeaturedBestInstructor-main-right-container col-8">
          <div className="FeaturedBestInstructor-main-Inner">
            <div className="container">
              <div className="d-flex flex-wrap gap-1 justify-content-center ">
                {trendingCoaches?.map((item, index) => {
                  return (
                    <div className="" key={`coach-${index}`}>
                      {" "}
                      <FeaturedProfileCard key={index} cardDetail={item} />{" "}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* <div className="col">
          <FeaturedProfileCard />
        </div>
        <div className="col">
          <FeaturedProfileCard />
        </div>
        <div className="col">
          <FeaturedProfileCard />
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default FeaturedBestInstructor;
