import axios from "axios";

/**
	  * @function getFeaturedCoachesService
	  * @param
	  * @description This function is used to list the popular coaches
*/
export const getFeaturedCoaches = async ({ trending='' ,perPageItem ='' ,page='' , status =''}) => {
	try {
		const url = `admin/coaches?${trending && "&trending=" + trending}${perPageItem && "&per_page=" + perPageItem} ${page && "&page=" + page}${status && "&status=" + status}`;
		const result = await axios.get(url);
		return result;
	} catch (error) {
		return error;
	}
};

/**
	* @function getCoachDetail
	* @param (id)
	* @description This function is used to get coach details
*/
export const getCoachDetail = async ({id ,page='',all_per_page='',upcoming_per_page='',past_per_page=''}) => {
	try {
		const result = await axios.get(`coach/coach-profile/${id}?${ page && "&page=" +page}${ all_per_page && "&all_per_page=" +all_per_page}${ upcoming_per_page && "&upcoming_per_page=" +upcoming_per_page}${ past_per_page && "&upcoming_per_page=" +past_per_page}`);
		return result;
	} catch (error) {
		return error;
	}
};
