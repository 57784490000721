import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reviewList: [],
};
const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {
    setReviewList: (state, action) => {
      state.reviewList = action.payload;
    },
  },
});
export const { setReviewList } = reviewSlice.actions;

export const selectReviewList = (state) => state.review.reviewList;

export default reviewSlice.reducer;
