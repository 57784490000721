import Footer from "../../components/common/footer/Footer";
import { Rating } from "react-simple-star-rating";

//image
import Home from "../../assets/images/Sidebar/Home.png";
import Navbar from "../../components/common/navbar/Navbar";
import ratingIcon from "../../assets/images/Rating/ratingIcon.svg";

//css
import "../../assets/scss/pages/certificateAuthentication/certificateAuthentication.scss";
//image
import image from "../../assets/images/Certificate/Certificate.png";
import Avator from "../../assets/images/Certificate/Avatar.png";

const CertificateDetails = () => {
  return (
    <div>
      <Navbar />

      <div className="px-3">
        <div className="d-flex mb-3 my-4 mx-3">
          <img
            src={Home}
            alt=""
            style={{ height: "15px", width: "15px" }}
            className="m-2"
          />
          <div className="py-1 px-2 font-mute">/</div>
          <div className="m-1  px-2  fw-semibold ">
            <small>My Account </small>
          </div>
          <div className="py-1 px-2 font-mute">/</div>
          <div className="m-1 my-account px-2 rounded fw-semibold ">
            <small>Certificate </small>
          </div>
        </div>

        <>
          <div className="my-4 mx-3">
            <h3 className="fw-bold">
              [Decision-Making Course] Master 4 Decision-Making Styles for
              Business Success
            </h3>
          </div>
          <div className=" mx-3 certificat-container-main gap-3 my-5">
            <div className="max-width-certificate">
              <div
                className="  p-4 rounded my-2"
                style={{ backgroundColor: "#FDF8EE" }}
              >
                <div>
                  <div className="d-flex gap-2 align-items-center ">
                    <div className=" overflow-hidden ">
                      <img
                        src={Avator}
                        alt="profile"
                        className="img-profile"
                      />
                    </div>
                    <div>
                      <div className="fw-semibold fs-5 mb-3">
                        Completed by Alexandra John
                      </div>
                      <div className="fw-semibold fs-6 my-2">
                        January 10, 2024
                      </div>
                      <div className="fw-semibold fs-6 mb-3">
                        1 hours 30 minutes (approximately)
                      </div>
                      <small>
                        ReSkills certifies their successful completion of
                        <br /> [Decision-Making Course] Master 4 Decision-
                        <br />
                        Making Styles for Business Success
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-3 border rounded  my-3">
                <p className="fw-bold">Learning Outcome</p>
                <ol>
                  <li>
                    Develop a profound understanding of key decision-making
                    styles with the potential to positively impact various
                    interactions, enhancing your business.
                  </li>
                  <li>
                    Gain the ability to identify sources of conflict within
                    decision-making processes, enabling you to independently
                    formulate winning plans tailored to specific situations,
                    fostering effective resolution.
                  </li>
                  <li>
                    Engage in a comprehensive case study, drawing parallels
                    between theoretical knowledge and real-life scenarios in
                    business contexts
                  </li>
                  <li>
                    Develop into an action-oriented individual by applying
                    insights gained from the case study to navigate and resolve
                    decision-making challenges.
                  </li>
                </ol>
              </div>
              <div className="p-3 border rounded my-3">
                <p className="fw-bold">Coach</p>
                <div className="d-flex gap-2 align-items-center">
                  <div className="rounded-circle overflow-hidden">
                    <img
                      src={Avator}
                      alt="profile"
                      style={{ width: "45px", height: "43px" }}
                    />
                  </div>
                  <div>
                    <div className="fw-bold fs-6">U K P MIHIRANGA</div>

                    <Rating
                      initialValue={5}
                      value={3}
                      customIcons={ratingIcon}
                      readonly={true}
                      size={20}
                      allowFraction={true}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-100  my-2">
              <img src={image} style={{ width: "100%" }} alt='cetificate' />
              <div className="my-4 px-4 button-container">
                <button className="btn btn-primary me-2  button primary" >Share Certificate</button>
                <button className="btn btn-outline-primary button outline" >Download Certificate</button>
              </div>
            </div>
          </div>
        </>
      </div>
      <Footer />
    </div>
  );
};

export default CertificateDetails;
