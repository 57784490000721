/* eslint-disable no-undef */
import { useState } from "react";

//importing styles
import "../../assets/scss/pages/onLive/onLive.scss";

//impoting images
import searchIcon from "../../assets/images/Onlive/searchIcon2.svg";

//importing components
import Navbar from "../../components/common/navbar/Navbar";
import Footer from "../../components/common/footer/Footer";
import LiveCourse from "../../components/Course/LiveCourse/LiveCourse";
import BannerWithText from "../../components/Banner/BannerWithText";
import Paginate from "../../components/common/paginate/Paginate";

const OnTheGo = () => {
  const [activeTab, setActiveTab] = useState();
  return (
    <>
      <Navbar />
      <div className="onLive-main-container">
        <BannerWithText title="OnTheGo" description="Take reskills anywhere you go, and embark on own personalized learning journey" />
        <LiveCourse />
        <div className="onLive-inner-container">
          <h2 className="fw-bold fs-4 mb-4">OnLIVE Classes</h2>
          <div className="onlive-tab-container d-flex align-items-center gap-2">
            <div
              onClick={() => setActiveTab(!activeTab)}
              className={`${activeTab && "active-bg"
                } d-flex align-items-center gap-2 px-3 py-1`}
            >
              <span className={`${activeTab ? "active-text" : "text-secondary"}`}>
                All
              </span>
              <span
                className={`${activeTab && "active-text"
                  } border border-secondary rounded-btn px-2`}
              >
                70
              </span>
            </div>
            <div
              onClick={() => setActiveTab(!activeTab)}
              className={`${activeTab && "active-bg"
                } d-flex align-items-center gap-2 px-3 py-1`}
            >
              <span className={`${activeTab ? "active-text" : "text-secondary"}`}>
                English
              </span>
              <span
                className={`${activeTab && "active-text"
                  } border border-secondary rounded-btn px-2`}
              >
                70
              </span>
            </div>
            <div
              onClick={() => setActiveTab(!activeTab)}
              className={`${activeTab && "active-bg"
                } d-flex align-items-center gap-2 px-3 py-1`}
            >
              <span className={`${activeTab ? "active-text" : "text-secondary"}`}>
                Chinese
              </span>
              <span
                className={`${activeTab && "active-text"
                  } border border-secondary rounded-btn px-2`}
              >
                70
              </span>
            </div>
            <div
              onClick={() => setActiveTab(!activeTab)}
              className={`${activeTab && "active-bg"
                } d-flex align-items-center gap-2 px-3 py-1`}
            >
              <span className={`${activeTab ? "active-text" : "text-secondary"}`}>
                Bahasa
              </span>
              <span
                className={`${activeTab && "active-text"
                  } border border-secondary rounded-btn px-2`}
              >
                70
              </span>
            </div>
            <div
              onClick={() => setActiveTab(!activeTab)}
              className={`${activeTab && "active-bg"
                } d-flex align-items-center gap-2 px-3 py-1`}
            >
              <span className={`${activeTab ? "active-text" : "text-secondary"}`}>
                Others
              </span>
              <span
                className={`${activeTab && "active-text"
                  } border border-secondary rounded-btn px-2`}
              >
                70
              </span>
            </div>
            <div
              onClick={() => setActiveTab(!activeTab)}
              className={`${activeTab && "active-bg"
                } d-flex align-items-center gap-2 px-3 py-1`}
            >
              <span className={`${activeTab ? "active-text" : "text-secondary"}`}>
                Special
              </span>
              <span
                className={`${activeTab && "active-text"
                  } border border-secondary rounded-btn px-2`}
              >
                70
              </span>
            </div>
          </div>
          <div className="onlive-filter-container d-flex align-items-center justify-content-between  mb-4 mt-3">
            <div className="search-container">
              <img src={searchIcon} alt="icon" />
              <input
                className="form-control px-5"
                type="search"
                placeholder="Search title"
                aria-label="Search"
              />
            </div>
            <div className="dropdown-container">
              <select className="dropdown-itemMenu">
                <option>category</option>
                <option className="dropdown-items">Eng</option>
                <option className="dropdown-items">Malay</option>
              </select>
            </div>
          </div>

          <Paginate />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OnTheGo;
