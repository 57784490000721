import React, { useEffect, useState } from 'react';
import { Spinner, Form } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import { pdfjs } from 'react-pdf';
import { useForm } from "react-hook-form";
import moment from 'moment';

import Footer from '../../components/common/footer/Footer';

//image
import Home from '../../assets/images/Sidebar/Home.png'
import Navbar from '../../components/common/navbar/Navbar';
import searchIcon from "../../assets/images/Navbar/search-Icon.svg";
import NoData from "../../components/common/noData/NoData";
import Button from '../../components/common/button/Button';
import share from '../../assets/images/LearnAndEarn/Icon.png'
import ShareModal from '../../components/Modals/ShareModal';


//css
import '../../assets/scss/pages/certificateAuthentication/certificateAuthentication.scss'
import { verifyCertificateService } from '../../services/certificate.service';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
//Skeleton



pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const CertificateAuthentication = () => {
    const [searchParams] = useSearchParams();

    const [loading, setLoading] = useState(true);
    const [serialNumber, setSerialNumber] = useState();
    const [certificate, setCertificate] = useState();
    const [baseUrl , setBaseUrl] = useState()
    const [pdfImage, setPdfImage] = useState()
    const [shareModal, setShareModal] = useState(false);
    const navigate = useNavigate()


    const { register, reset, handleSubmit, formState: { errors } } = useForm({
        mode: "onSubmit"
    });

    const verifyCertificate = async (serial_number) => {
        setLoading(true)
        setCertificate()
        
        setPdfImage()
        const result = await verifyCertificateService({
            serial_number
        });
        if (result?.data?.certificate) {
            navigate(`/certificate/verify?serial_number=${serial_number}`)
            setCertificate(result?.data?.certificate)
            
            const image = await getPageAsImage(result?.data?.certificate?.pdf, 1);
            setPdfImage(image)
        }
        setLoading(false)
    };
    const handleShareModal = () => {
        setShareModal(!shareModal)
    }

    const getPageAsImage = async (pdfUrl, pageNumber) => {
        const pdf = await pdfjs.getDocument(pdfUrl).promise;
        const page = await pdf.getPage(pageNumber);
        const viewport = page.getViewport({ scale: 2 });

        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const renderContext = {
            canvasContext,
            viewport,
        };

        await page.render(renderContext).promise;
        return canvas.toDataURL('image/png');
    }

    useEffect(() => {
        if (searchParams?.get('serial_number')) {
            setSerialNumber(searchParams?.get('serial_number'))
            verifyCertificate(searchParams?.get('serial_number'))
        } else {
            setLoading(false)
        }
        // eslint-disable-next-line
    }, [searchParams]);

    /**
        * @function onSubmit
        * @param (formdata)
        * @description This function is used to search certificate using serial number
    */
    const onSubmit = async (formdata) => {
        verifyCertificate(formdata?.serial_number)
        setSerialNumber(formdata?.serial_number)
        reset()
    }

    useEffect(() => {
        const hostname = window.location.hostname; // Use window.location to get the hostname
       
        if (hostname === 'reskills.com') {
            setBaseUrl('https://reskills.com');
        } else if (hostname === 'webv2.reskills.com') {
            setBaseUrl('https://webv2.reskills.com');
        } else if (hostname === 'localhost') {
            setBaseUrl('http://localhost:3000');
        } else {
            setBaseUrl('https://reskills.com'); // Fallback to reskills.com if the hostname doesn't match
        }
    }, []);
    
    


    return (
        <div>
            <Navbar />

            <div className='mx-5'>
                <div className='d-flex mb-3 my-4 '>
                    <Link to='/'>
                        <img src={Home} alt='' style={{ height: "15px", width: "15px" }} className='m-2' />
                    </Link>
                    <div className='py-1 px-2 font-mute'>/</div>
                    <div className='m-1 my-account px-2 rounded fw-semibold '><small >Certificate Authenticator</small></div>

                </div>

                {loading ?
                    <div className='d-flex justify-content-center align-items-center my-5 w-100'>
                        <Spinner className='mx-auto' animation="border" />
                    </div>
                    :
                    certificate ?
                        <>
                            <div className='my-4'>
                                <small>Serial Number</small>
                                <h5 className='fw-sembold'>{serialNumber}</h5>
                            </div>
                            <div className="m mx-3 d-md-flex gap-3 align-items-start my-5">
                                <div className="w-md-75 w-100 my-2">
                                    <img src={pdfImage} style={{ width: "100%" }} alt='certificate' />
                                </div>
                                <div className="w-md-25 w-100">
                                <div className="w-md-25 w-100 p-4 rounded my-2" style={{ backgroundColor: "#eef2f6" }}>
                                    <p className="fs-5  fw-semibold">ReSkills Certificate Authentication</p>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="fs-6">Full Name</p>
                                        <p className="fs-6 fw-semibold text-end">{certificate?.user_full_name}</p>
                                    </div>
                                    <div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="fs-6">Coach Name</p>
                                            <p className="fs-6 fw-semibold text-end">{certificate?.coach?.full_name}</p>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="fs-6">Course Type</p>
                                            <p className="fs-6 fw-semibold text-end">{certificate?.class_name}</p>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="fs-6">Date issue</p>
                                            <p className="fs-6 fw-semibold text-end">{moment(certificate?.date)?.format("D/M/YYYY")}</p>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <p className="fs-6">Status</p>
                                            <p className="fs-6 px-2 fw-semibold text-white border rounded-pill bg-sub-sucess">Verified</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='bg-muted rounded my-3'>
                            <div className='fw-semibold fw-semibold'> Click to share the digital certificate link with others.</div>
                            <div className='my-3 share-btn-flex'>
                                <div className='url-text'>
                                <div className='fw-semibold text-primary max-w-flex'>{`${baseUrl}/certificate/verify?serial_number=${serialNumber}`}</div>

                                </div>
                                <div onClick={handleShareModal} className='flex-btn-width '>
                                    <Button
                                        name={"Share"}
                                        icon={share}

                                    />

                                </div>

                            </div>
                        </div>

                                </div>
                                
                            </div>
                        </>
                        :
                        serialNumber ?
                            <div className=" img-load d-flex justify-content-center align-item-center custom-container">
                                <NoData page="user-certificate-verify" />
                            </div>
                            :
                            <></>
                }
                {!loading &&
                    <>
                        <p className='text-center fs-5 fw-semibold'>Want to search other serial number?</p>
                        <div className='d-flex justify-content-center my-4 w-100 '>
                            <div className="d-flex position-relative justify-content-center " >
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="search-main-container ">
                                        <img src={searchIcon} alt="search-icon" className="search-icon" />
                                        <input
                                            className="form-control ml-2  mx-2 bg-transparent border ser-width"
                                            type="search"
                                            id="input-field"
                                            placeholder="Search"
                                            aria-label="Search"
                                            {...register("serial_number", {
                                                required: "Serial Number is required"
                                            })}
                                        />
                                    </div>
                                    {errors.serial_number && <p style={{ fontSize: "13px", color: "#f04438" }}>
                                        {errors.serial_number.message}
                                    </p>}
                                </Form>
                            </div>
                        </div>
                    </>
                }
            </div>
            <ShareModal show={shareModal} handleClose={handleShareModal} shareUrl={`${baseUrl}/certificate/verify?serial_number=${serialNumber}`} />

            <Footer />
        </div>
    );
};

export default CertificateAuthentication;