import { useState } from "react";

//import images
import BlueBGIcon from "../../../../assets/images/CategoryFeatured/arrowIcon2.svg";
import UpRightArrow from "../../../../assets/images/CategoryFeatured/ArrowUpRight.svg";
import announcementIcon from "../../../../assets/images/CategoryFeatured/announcement.svg";
import checkIcon from "../../../../assets/images/InterestSelectionPage/checkIcon.svg";
import checkHoverIcon from "../../../../assets/images/InterestSelectionPage/checkHoverIcon.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

//importing styles
import "../../../../assets/scss/components/card/categoryCard/categoryCard.scss";
import Skeleton from "react-loading-skeleton";

const CategoryCard = ({ cardType, cardDetail, selectedCategories }) => {
  const [active, setActive] = useState(false);
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();

  const handleClick = (cardDetail) => {
    setActive(!active);
    const id = cardDetail?.id;

    if (!selectedCategories?.includes(id)) {
      selectedCategories.push(id);
    } else {
      selectedCategories.splice(selectedCategories?.indexOf(id), 1);
    }
  };



  return (
    <div
      className="category-card-container card hover-border shadow-sm bg-white rounded px-3 py-2 cursor-pointer mb-4"
      onClick={() => {
        cardType !== "cardWithCheckHover" && handleClick(cardDetail);
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="card-body d-flex align-items-center justify-content-between "
        onClick={() => {
          if (cardType === "cardWithCheckHover") {
            navigate(`/catagory?search=${encodeURIComponent(cardDetail.name)}`)
          }
        }}
      >
        <div className="d-flex align-items-center gap-4">
          <div className="">
            {cardDetail?.image ?
              <img
                src={cardDetail?.image ? cardDetail?.image : announcementIcon}
                alt="featureIcon"
                className="card-image"
                style={{ height: '50px', width: '50px' }}
              /> :
              <Skeleton circle height={30} width={30} />
            }
          </div>
          <h6 className="fw-bold text-start">{cardDetail?.name || <Skeleton width={200} count={2} />}</h6>
        </div>
        {cardType === "cardWithCheckHover" ? (
          <div className="card-right-body">
            {hover ? (
              <img src={checkHoverIcon} alt="icon" />
            ) : (
              <img src={checkIcon} alt="icon" />
            )}
          </div>
        ) : (
          <div className="card-right-body">
            {active ? (
              <img src={BlueBGIcon} alt="icon" />
            ) : (
              <img src={UpRightArrow} alt="icon" />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryCard;
