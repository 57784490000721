import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

//importing styles
import "../../assets/scss/pages/auth/resetPassword/resetPassword.scss";

//importing components
import Input from "../../components/common/input/Input";
import Button from "../../components/common/button/Button";
import PasswordStrength from "../../components/common/passwordStrength/PasswordStrength";

//importing images
import backIcon from "../../assets/images/Auth/BackIcon.svg";
import lockIcon from "../../assets/images/Auth/lockIcon.svg";

//importing services
import { resetPassword } from "../../services/auth.service";

const ResetPassword = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    password: "",
    password_confirmation: "",
  });
  const [isloading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  /**
   * @function handleResetPassword
   * @param {email,otp, password, password_confirmation}
   * @description This function is used to reset password
   */

  const handleResetPassword = async () => {
    setIsLoading(true);
    const result = await resetPassword({
      email: state.email,
      otp: state.otp,
      password: formData.password,
      password_confirmation: formData.password_confirmation,
    });
    if (result?.response?.data?.errors) {
      setErrorMessage(result?.response?.data?.errors);
    }
    if (result?.data?.status === "success") {
      navigate("/reset-password-success");
    }
    setIsLoading(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <div className="resetPassword-container mx-auto mt-5" style={{ width: "26%" }}>
      <div className="text-center">
        <img src={lockIcon} alt="siteLogo" className="mb-4" />
        <h2 className="fw-bold fs-3 mb-2">Set New Password</h2>
        <p className="mb-4 text-secondary fs-6">
          Your new password must be different to previously used passwords.
        </p>
      </div>
      <div className="mb-3">
        <label className={`${errorMessage?.password && "errorColor"}   fs-6`}>
          Password
        </label>
        <Input
          errorClass={`${errorMessage?.password && "error-border"} `}
          name="password"
          placeholder="New password"
          type="password"
          onChange={(e) => handleChange(e)}
        />
        {errorMessage?.password && (
          <p style={{ fontSize: "13px", color: "#f04438" }}>
            {errorMessage?.password[0]}
          </p>
        )}
        {formData?.password && (
          <PasswordStrength password={formData?.password} />
        )}
      </div>
      <div className="mb-3">
        <label className="fs-6">Confirm password</label>
        <Input
          name="password_confirmation"
          placeholder="Confirm password"
          type="password"
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div>
        <Button 
          disable={!/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/.test(formData.password) || isloading} name={`${isloading ? "Loading..." : "Reset password"}`}
          onClick={() => handleResetPassword()}
        />
      </div>
      <div
        className="justify-content-center d-flex align-item-center gap-2 mt-4 cursor-pointer"
        onClick={() => navigate("/login")}
      >
        <img src={backIcon} alt="backIcon" />
        <p className="m-0 fs-6 fw-bold">Back to Login</p>
      </div>
    </div>
  );
};

export default ResetPassword;
