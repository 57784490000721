import moment from "moment";

//import react rating
import { Rating } from "react-simple-star-rating";

//importing images
import ratingIcon from "../../../../assets/images/Rating/ratingIcon.svg";
// import avatar from "../../../../assets/images/Cards/HorizentalCard/Avatar.svg";

//importing styles
import "../../../../assets/scss/components/card/CoachCard/CoachCard.scss";
import avatar from "../../../../assets/images/Auth/avatar2.svg";

import Image from "../../image";

// import cover from "../../../../assets/images/CoachReview/cover.png";


const CoachCard = (props) => {


    return (
        <div>
            <div className="coachCard-main-container cursor-pointer mx-2">
                <div className="card border-0 shadow-sm mb-4">
                    <div className="card-body-review">
                        <div className="d-flex justify-content-between align-items-center mt-3 ">
                            <div className="d-flex gap-2 align-items-center">
                                <div className="rounded-circle overflow-hidden">

                                    <Image
                                        src={props?.item?.user?.image } 
                                        alt="bg" 
                                        // className="coach-bgImg" 
                                        defaultImage={avatar}
                                        style={{ width: "45px", height: "45px" }}

                                    />
                                </div>
                                <div>
                                    <div className="d-flex">
                                        <p className="mb-0 fw-bold text-capitalize overflow-hidden">{props?.item?.user?.full_name}</p>
                                    </div>
                                    <Rating
                                        initialValue={props?.item?.rating?.toFixed(2)}
                                        value={props?.item?.rating?.toFixed(2)}
                                        customIcons={ratingIcon}
                                        readonly={true}
                                        size={20}
                                        allowFraction={true}
                                    />

                                </div>
                            </div>
                        </div>
                        <a className="text-capitalize" href='/'>classes attended: {props?.item?.class?.title}</a>
                        <p className="fw-medium pt-1">{props?.item?.review}</p>
                        <p>{moment(props?.item?.updated_at).format("D MMM YYYY")}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CoachCard;
