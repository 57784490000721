import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import CertificateCard from "../cards/CertificateCard/CertificateCard";
import { getCertificateService } from "../../../services/certificate.service";
import { setCertificateDetail, selectCertificateDetail } from "../../../redux/certificateSlice";

// Importing styles
import "../../../assets/scss/components/paginate/paginate.scss";
import NoData from "../noData/NoData";

function Items({ currentItems }) {
  return (
    <>
      {currentItems?.length > 0 ? (
        <div>
          {currentItems?.map((item) => (
            <div key={item.id}>
              <CertificateCard cardDetail={item} />
            </div>
          ))}
        </div>
      ) : (
        <NoData page="user-certificate" />
      )}
    </>
  );
}

const CertificatePaginate = ({ totalItem, selectedClass, searchQuery }) => {
  const dispatch = useDispatch();
  const Classes = useSelector(selectCertificateDetail);

  const totalPage = Number(totalItem) / 8;

  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = 8;
  const pageCount = Math.ceil(Number(totalItem) / itemsPerPage);

  const handlePageClick = async (event) => {
    const newOffset = (event.selected * itemsPerPage) % totalPage;
    setItemOffset(newOffset);
    setCurrentPage(event.selected);

    const result = await getCertificateService({
      class_type: selectedClass,
      page: event.selected + 1,
      searchQuery
    });
    dispatch(setCertificateDetail(result?.data?.certificates?.data));
  };

  useEffect(() => {
   
      (async () => {
        const result = await getCertificateService({
          class_type: selectedClass,
          page: currentPage + 1,
          searchQuery
        });
        dispatch(setCertificateDetail(result?.data?.certificates?.data));
      })();
    
  }, [searchQuery, selectedClass, currentPage, dispatch]);

  return (
    <>
      <Items currentItems={Classes} />
      <div className={"d-flex justify-content-center"}>
        <ReactPaginate
          breakLabel="..."
          nextLabel={currentPage === pageCount - 1 ? null : "Next"}
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel={currentPage === 0 ? null : "Previous"}
          renderOnZeroPageCount={null}
          className="paginationComponent"
          containerClassName={"pagination"}
          previousClassName={currentPage === 0 ? "previous hide-arrow" : "previous"}
          nextClassName={currentPage === pageCount - 1 ? "hide-next-arrow" : "next"}
        />
      </div>
    </>
  );
};

export default CertificatePaginate;
