import axios from "axios";

/**
    * @function getSubscriptionStatus
    * @param
    * @description This function is used to fetch the status of user's subscription
*/
export const getSubscriptionStatus = async () => {
    try {
        const result = await axios.get(`user/subscription-status`);
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function subscriptionDetailsService
    * @param
    * @description This function is used to fetch the details of subscription
*/
export const subscriptionDetailsService = async () => {
    try {
        const result = await axios.get(`user/subscription-details`);
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function paymentService
    * @param
    * @description This function is used to make the payment
*/
export const paymentService = async () => {
    try {
        const result = await axios.post(`user/payment-token-api`, {});
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function applyPromoCodeService
    * @param promo_code
    * @description This function is used to apply promo code
*/
export const applyPromoCodeService = async (promo_code) => {
    try {
        const result = await axios.post(`user/apply-promo-code`, {
            promo_code
        });
        return result;
    } catch (error) {
        return error;
    }
};
