import axios from "axios";

/**
    * @function redeemScratchCodeService
    * @param scratch_code
    * @description This function is used to redeem scratch code
*/
export const redeemScratchCodeService = async (scratch_code) => {
    try {
        const result = await axios.post(`user/redeem-scratch-code`, {
            scratch_code
        });
        return result;
    } catch (error) {
        return error;
    }
};