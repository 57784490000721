import { Link, useNavigate } from "react-router-dom";
import "core-js/actual/array/group-by-to-map";
import "core-js/actual/array/group-by";

//importing styles
import "../../assets/scss/components/modal/searchModal.scss";

const SearchModal = ({ isLoading, searchQuery, searchResult }) => {
  const navigate = useNavigate();

  const groupByClassType = (searchResult || []).reduce((acc, classes) => {
    const key = classes?.class_type?.name;
    if (key) {
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(classes);
    }
    return acc;
  }, {});

  return (
    <div className="search-modal-container">
      <div className="row">
        <div className="card-container">
          <div className="card shadow-sm">
            <div className="card-body">
              {/* searchResult !== null && {} */}
              {isLoading ? (
                <p>Loading...</p>
              ) : (
                searchResult !== null &&
                (searchResult?.length > 0 ? (
                  Object?.keys(groupByClassType)?.map((searchedItem) => {
                    return (
                      <>
                        <h5 className="card-title fw-bold mb-4">{searchedItem}</h5>

                        {groupByClassType?.[searchedItem].map(
                          (nestedSearchItem) => {
                            return (
                              <div
                                className="d-flex align-items-start gap-2 mb-2 cursor-pointer"
                                onClick={() =>
                                  navigate(`/class/${nestedSearchItem?.slug}`)
                                }
                              >
                                <img
                                  src={nestedSearchItem?.thumb_image}
                                  alt="thumb"
                                  className="col-1"
                                />
                                <div>
                                  <p className="card-text m-0 fs-6">
                                    {nestedSearchItem?.title}
                                  </p>
                                  <p className="card-text text-primary p-0 fs-6">
                                    {nestedSearchItem?.class_type?.name}
                                  </p>
                                </div>
                              </div>
                            );
                          }
                        )}
                      </>
                    );
                  })
                ) : (
                  <>
                    <p>No result found</p>
                  </>
                ))
              )}
              {searchResult?.length > 0 && (
                <>
                  <Link to={`/catagory?search=${encodeURIComponent(searchQuery)}`} className='text-decoration-none'>
                    <p className="card-text text-primary mt-3 cursor-pointer fs-6">
                      View all courses
                    </p>
                  </Link>
                  <hr className="text-secondary my-3" />
                </>
              )}
              {/* <h5 className="card-title fw-bold mb-3 f">Popular Searches</h5>
              <div className="d-flex align-items-center gap-2 mb-2">
                <img src={searchIcon2} alt="image" />
                <div>
                  <p className="card-text m-0 fs-6">On the go</p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-2 mb-2">
                <img src={searchIcon2} alt="image" />
                <div>
                  <p className="card-text m-0 fs-6">Marketing</p>
                </div>
              </div>
              <div className="d-flex align-items-center gap-2 mb-2">
                <img src={searchIcon2} alt="image" />
                <div>
                  <p className="card-text m-0 fs-6">On the live</p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchModal;
