import Slider from "react-slick";
import { useSelector } from "react-redux";
import { useEffect,useState } from "react";

//importing styles
import "../../assets/scss/components/banner/banner.scss";

//importing component
import FeatureCard from "../common/cards/FeatureCard/FeatureCard";
//api
import { getBannerList } from "../../services/dashboard.service";
//importing redux function
import { useDispatch } from "react-redux";
import { selectBannerList,setBannerList } from "../../redux/bannerSlice";
import { selectStatsList } from "../../redux/statsService";
import Skeleton from "react-loading-skeleton";

const Banner = () => {
    const settings = {
        dots: true,
        infinite: true,
        arrow: false,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    

     /**
        * @function fetchBanners
        * @param
        * @description This function is used fetch banner List
    */
     const fetchBanners = async () => {
        try {
            setLoading(true)
            const result = await getBannerList();
            dispatch(setBannerList(result?.data?.banners));
            setLoading(false)

        } catch (error) {
            console.log(error);
        }

    };


    const dashboardBanner = useSelector(selectBannerList)?.filter(
        (item) => item.type === "on_dashboard" && item?.platform === "web"
    );


    const dashboardStats = useSelector(selectStatsList);
    // const arr = [...dashboardStats];
    // const sortedArray = arr.sort((a, b) => {
    //     if (a.sequence === null) {
    //         return 1;
    //     }
    //     if (b.sequence === null) {
    //         return -1;
    //     }
    //     return a.sequence - b.sequence;
    // });

    


    useEffect(() => {
        fetchBanners();
    }, []);


    return (
        <div className="banner-main">
        {/* <div className="banner-main overflow-x-hidden"> */}
            {!loading ?
            <div className="banner-slider">
                <Slider {...settings}>
                    {dashboardBanner?.map((item, index) => {
                        return (
                            <div className="banner-image-main" key={index}>
                                <a href={item?.link} target="_blank" rel="noopener noreferrer">
                                    <img
                                        src={item?.image}
                                        alt="Bannerimage"
                                        className="img-fluid"
                                    />
                                </a>
                            </div>
                        );
                    })}
                    
                </Slider>
            </div>:
                <Skeleton height={400}/>
            }
            {
                // <div className="banner-cards-main px-4 ">
                //     {sortedArray?.map((item) => {
                //         return <FeatureCard cardContent={item} />;
                //     })}
                // </div>
            }
        </div>
    );
};

export default Banner;
