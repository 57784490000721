/* eslint-disable jsx-a11y/img-redundant-alt */
import { Rating } from "react-simple-star-rating";
import ReactPlayer from "react-player";

//importing components
import Button from "../../components/common/button/Button";

//importing images
import avatar from "../../assets/images/Cards/HorizentalCard/Avatar.svg";
import ratingIcon from "../../assets/images/Rating/ratingIcon.svg";
import categoryIcon from "../../assets/images/Onlive/categoryIcon.svg";
import dateIcon from "../../assets/images/Onlive/dateIconn.svg";
import timeIcon from "../../assets/images/Onlive/timeIcon.svg";
import languageIcon from "../../assets/images/Onlive/languageIcon.svg";
import materialIcon from "../../assets/images/Onlive/metarialIcon.svg";
import certificateIcon from "../../assets/images/Onlive/certificateIcon.svg";
import arrowIcon from "../../assets/images/Onlive/downArrow.svg";
import calenderIcon from "../../assets/images/Onlive/calenderIcon.svg";
import fileIcon from "../../assets/images/Onlive/FileIcon.svg";
import viewIcon from "../../assets/images/Onlive/viewIcon.svg";
import downloadIcon from "../../assets/images/Onlive/downloadIcon.svg";
//import icon
import bookmarkIcon from "../../assets/images/OnTheGoDetailPage/bookmark.svg";

//importing styles
import "../../assets/scss/pages/onTheGoDetailPage/onTheGoDetailPage.scss";

//importing components
import VerticalCard from "../../components/common/cards/courseCards/VerticalCard";
import SiteSlider from "../../components/common/slider/SiteSlider";

const OnTheGoDetailPage = () => {
  return (
    <div className="onLiveDetailPage-main-container">
      <div className="w-100 my-auto">
        <ReactPlayer
          url="https://www.youtube.com/watch?v=LXb3EKWsInQ"
          width="100%"
          height="500px"
          style={{ borderRadius: "12px" }}
        />
      </div>
      {/* <Banner/> */}
      <div className="mt-4">
        <h2 className="fw-bold">On the go</h2>
        <div className="d-flex align-items-center gap-2 mt-4">
          <Rating initialValue={3} size={25} />
          <span className="m-0 p-0">5.0</span>
        </div>
      </div>
      <div className="onLiveDetailPage-inner-container d-flex gap-5 mt-5">
        <div className="left-inner-container">
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book.
          </p>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. Lorem Ipsum is simply
            dummy text of the printing and typesetting industry. Lorem Ipsum has
            been the industry's standard dummy text ever since the 1500s, when
            an unknown printer took a galley of type and scrambled it to make a
            type specimen book.
          </p>
        </div>
        <div className="right-inner-container">
          <div className="d-flex  justify-content-between">
            <p className="text-primary">OnLive</p>
            <div>.</div>
            <p className="text-primary">English</p>
            <div>.</div>
            <p className="text-primary">Business</p>
            <div>.</div>
            <p className="text-primary">Market Research</p>
          </div>

          <div className="mt-2">
            <Button name="Bookmark" btnType="whiteButton" icon={bookmarkIcon} />
          </div>
          <div className="border border-secondary rounded p-3 my-4 bg-white">
            <h5>Coach</h5>
            <div className="d-flex gap-2 align-items-center">
              <div style={{ width: "50px" }}>
                <img
                  src={avatar}
                  alt="profile-picture"
                  className="rounded-circle w-100"
                />
              </div>
              <div>
                <p className="mb-0 ">Name</p>
                <Rating
                  initialValue={3}
                  customIcons={ratingIcon}
                  readonly={true}
                  size={20}
                />{" "}
              </div>
            </div>
          </div>
          <div className="hover-effect cursor-pointer d-flex align-items-center justify-content-between border border-secondary cursor-pointer rounded p-3 mb-2 bg-white">
            <div className="d-flex align-items-center gap-2">
              <img src={categoryIcon} alt="category-icon" />
              <p className="m-0 gray-700">Category</p>
            </div>
            <p className="text-secondary m-0">Business</p>
          </div>
          <div className="hover-effect cursor-pointer d-flex align-items-center justify-content-between border border-secondary rounded p-3 mb-2 bg-white">
            <div className="d-flex align-items-center gap-2">
              <img src={dateIcon} alt="category-icon" />
              <p className="m-0 gray-700">Date</p>
            </div>
            <p className="text-secondary m-0">Wednesday, 29 march</p>
          </div>
          <div className="hover-effect cursor-pointer d-flex align-items-center justify-content-between border border-secondary rounded p-3 mb-2 bg-white">
            <div>
              <div className="d-flex align-items-center gap-2">
                <img src={timeIcon} alt="category-icon" />
                <p className="m-0 gray-700">Time</p>
              </div>
              <div className="tag-container">
                <span>
                  Time Zone : GMT +8 <img src={arrowIcon} alt="icon" />
                </span>
              </div>
            </div>
            <p className="text-secondary m-0">09:00 PM</p>
          </div>
          <div className="hover-effect cursor-pointer d-flex align-items-center justify-content-between border border-secondary rounded p-3 mb-2 bg-white">
            <div className="d-flex align-items-center gap-2">
              <img src={timeIcon} alt="category-icon" />
              <p className="m-0 gray-700">Duration</p>
            </div>
            <p className="text-secondary m-0">70 mins</p>
          </div>
          <div className="hover-effect cursor-pointer d-flex align-items-center justify-content-between border border-secondary rounded p-3 mb-2 bg-white">
            <div className="d-flex align-items-center gap-2">
              <img src={languageIcon} alt="category-icon" />
              <p className="m-0 gray-700">Language</p>
            </div>
            <p className="text-secondary m-0">English</p>
          </div>
          <div className="hover-effect cursor-pointer  border border-secondary rounded p-3 mb-2 bg-white">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-2">
                <img src={materialIcon} alt="category-icon" />
                <p className="m-0 gray-700">Material</p>
              </div>

              <p className="text-secondary m-0">Login to view</p>
            </div>
            <div className="d-flex align-items-center justify-content-between  mt-3">
              <div className="d-flex align-items-center gap-2">
                <img src={fileIcon} alt="category-icon" />
                <p className="m-0 gray-700 fs-6">pdf1.pdf</p>
              </div>

              <div className="m-0 d-flex gap-3">
                <img src={viewIcon} alt="icon" />
                <img src={downloadIcon} alt="icon" />
              </div>
            </div>
          </div>
          <div className="hover-effect cursor-pointer d-flex align-items-center justify-content-between border border-secondary rounded p-3 mb-2 bg-white">
            <div className="d-flex align-items-center gap-2">
              <img src={certificateIcon} alt="category-icon" />
              <p className="m-0 gray-700">Certificate</p>
            </div>
            <p className="text-secondary m-0">Yes</p>
          </div>
          <div className="cursor-pointer d-flex gap-2 justify-content-center mt-4">
            <img src={calenderIcon} alt="icon" />
            <span className="text-primary">Add to calendar</span>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <h3 className="fw-bolder">Similiar Courses</h3>
        <div className="d-flex align-items-center justify-content-between">
          <p className="text-primary fw-bold">Learn Anytime, Anywhere</p>
          <Button name="Explore All" btnType="whiteButton" />
        </div>
        <SiteSlider cardType="vertical">
          <VerticalCard />
          <VerticalCard />
          <VerticalCard />
          <VerticalCard />
          <VerticalCard />
        </SiteSlider>
      </div>
    </div>
  );
};

export default OnTheGoDetailPage;
