import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

//importing component
import FeatureCard from "../common/cards/FeatureCard/FeatureCard";

//importing styles
import "../../assets/scss/components/features/features.scss";

//importing redux
import { selectClassTypes } from "../../redux/classSlice";

const Features = () => {
    const classTypes = useSelector(selectClassTypes)?.filter((item) => item);
    const arr = [...classTypes];
    const sortedArray = arr.sort((a, b) => {
        if (a.sequence === null) {
            return 1;
        }
        if (b.sequence === null) {
            return -1;
        }
        return a.sequence - b.sequence;
    });

    return (
        <>
            {sortedArray?.length > 0 && (
                <div className="features-main-container my-5">
                    <h2 className="feature-main-heading fw-bold fs-4 mb-4">
                        Browse Features
                    </h2>
                    {/* vertical feature card */}
                    <div className="feature-main-card-container">
                        {sortedArray?.map((item, index) => {
                            return (
                                <Link 
                                    className="text-decoration-none" 
                                    to={item?.slug === "1-on-1" 
                                            ? "https://about.reskills.com/1-on-1" 
                                            : item?.slug === "on1"
                                            ? "https://about.reskills.com/reskills-on-1/" 
                                            : `/classes/${item?.slug}`} 
                                    target={(item?.slug === "1-on-1" || item?.slug === "on1" )? "_blank" : "_self"} 
                                    key={`feature-${index}`}>
                                    <div>
                                        <FeatureCard cardType="vertical" cardContent={item} />
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            )}
        </>
    );
}

export default Features;
