import axios from "axios";

/**
    * @function getRegisteredClassListService
    * @param { searchQuery, perPageItem, page, category }
    * @description This function is used to fetch the registered classes
*/
export const getRegisteredClassListService = async ({ searchQuery = "", perPageItem = "", page = "", category = "", tab = "" }) => {
    try {
        const result = await axios.get(`user/registered-class-list?${searchQuery && "&search=" + searchQuery}${category && "&category_slug=" + category}${page && "&page=" + page}${perPageItem && "&per_page=" + perPageItem}${tab && "&class_type_slug=" + tab}`);
        // const filteredResult = result.data?.registered_classes
        // return filteredResult;
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function getJoinedClassListService
    * @param { searchQuery, perPageItem, page, category }
    * @description This function is used to fetch the joined classes
*/
export const getJoinedClassListService = async ({ searchQuery = "", perPageItem = "9", page = "", category = "", tab = "" }) => {
    try {
        const result = await axios.get(`user/joined-class-list?${searchQuery && "&search=" + searchQuery}${category && "&category_slug=" + category}${page && "&page=" + page}${perPageItem && "&perPage=" + perPageItem}${tab && "&class_type_slug=" + tab}`);
        // const filteredResult = result.data?.joined_classes
        // return filteredResult;
        return result;
    } catch (error) {
        return error;
    }

};

/**
    * @function getBookmarkListService
    * @param
    * @description This function is used to fetch the bookmark classes
*/
export const getBookmarkListService = async ({ searchQuery = "", perPageItem = "9", page = "", category = "" }) => {
    try {
        const result = await axios.get(`user/bookmarks?${searchQuery && "&search=" + searchQuery}${category && "&category_slug=" + category}${page && "&page=" + page}${perPageItem && "&perPage=" + perPageItem}`);
        return result;
    } catch (error) {
        return error;
    }
};