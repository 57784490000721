import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import axios from "axios";
import Cookies from "js-cookie";

// Redux Store
import { store } from "./redux/store";

import "bootstrap/dist/css/bootstrap.min.css";

//importing global style
import './assets/scss/index.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css'


import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "./i18n/config"

axios.interceptors.request.use((request) => {
    if (request.url) {
        request.url = process.env.REACT_APP_API_BACKEND_SERVER + request.url;
    }
    if (Cookies.get('tokenAccess')) {
        request.headers['Authorization'] = `Bearer ${Cookies.get('tokenAccess')}`;
    }
    return request;
})



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>

        </Provider>
    </React.StrictMode>
);

serviceWorkerRegistration.register({
    onUpdate: (registration) => {
        const waitingServiceWorker = registration.waiting;

        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener("statechange", (event) => {
                if (event.target.state === "activated") {
                    window.location.reload();
                }
            });
            waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
        }
    }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
