import axios from "axios";

/**
    * @function getAssessmentByClass
    * @param { classname }
    * @description This function is used to get the assesment by the given class
*/
export const getAssessmentByClass = async ({ classname }) => {
    try {
        const result = await axios.get(`user/get-assessment/${classname}`);
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function getAssessmentRecordByClass
    * @param { assessment_id }
    * @description This function is used to get the assesment record by the given class
*/
export const getAssessmentRecordByClass = async ({ assessment_id }) => {
    try {
        const result = await axios.get(`user/assessment-record?assessment_id=${assessment_id}`);
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function submitAssesssment
    * @param { classSlug, assessment_id, answers }
    * @description This function is used to submit the assessment
*/
export const submitAssessment = async ({ classSlug, assessment_id, answers }) => {
    try {
        const result = await axios.post(`user/submit-assessment`, {
            class_slug: classSlug,
            assessment_id: assessment_id,
            answers: answers
        });
        return result;
    } catch (error) {
        return error;
    }
}
