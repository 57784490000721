import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    CertificateDetail: "",
};
const CertificateSlice = createSlice({
  name: "certificateList",
  initialState,
  reducers: {
    setCertificateDetail: (state, action) => {
      state.CertificateDetail = action.payload;
    },
    
  },
});
export const { setCertificateDetail} = CertificateSlice.actions;

export const selectCertificateDetail = (state) => state.certificateList.CertificateDetail;

export default CertificateSlice.reducer;