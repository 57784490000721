import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  classList: [],
  registeredClassList: [],
  joinedClassList: [],
  bookmarkClassList: [],
  todayLiveClasses: [],
  onLiveClasses: [],

  onFerenceClasses: [],
  onTheGoClasses: [],
  onStageClasses: [],

  classTypes: [],
};
const classSlice = createSlice({
  name: "classes",
  initialState,
  reducers: {
    setClassList: (state, action) => {
      state.classList = action.payload;
    },
    setRegisteredClassList: (state, action) => {
      state.registeredClassList = action.payload;
    },
    setJoinedClassList: (state, action) => {
      state.joinedClassList = action.payload;
    },
    setBookmarkClassList: (state, action) => {
      state.bookmarkClassList = action.payload;
    },
    setTodayLiveClasses: (state, action) => {
      state.todayLiveClasses = action.payload;
    },
    setOnLiveClasses: (state, action) => {
      state.onLiveClasses = action.payload;
    },
    setOnFerenceClasses: (state, action) => {
      state.onFerenceClasses = action.payload;
    },
    setOnTheGoClasses: (state, action) => {
      state.onTheGoClasses = action.payload;
    },
    setOnStageClasses: (state, action) => {
      state.onStageClasses = action.payload;
    },
    setClassTypes: (state, action) => {
      state.classTypes = action.payload;
    },
  },
});
export const {
  setClassList,
  setRegisteredClassList,
  setJoinedClassList,
  setBookmarkClassList,
  setClassTypes,
  setTodayLiveClasses,
  setOnLiveClasses,
  setOnFerenceClasses,
  setOnTheGoClasses,
  setOnStageClasses,
} = classSlice.actions;

export const selectClassList = (state) => state.classes.classList;
export const selectRegisteredClassList = (state) => state.classes.registeredClassList;
export const selectJoinedClassList = (state) => state.classes.joinedClassList;
export const selectBookmarkClassList = (state) => state.classes.bookmarkClassList;
export const selectTodayLiveClasses = (state) => state.classes.todayLiveClasses;
export const selectOnLiveClasses = (state) => state.classes.onLiveClasses;
export const selectOnFerenceClasses = (state) => state.classes.onFerenceClasses;
export const selectOnTheGoClasses = (state) => state.classes.onTheGoClasses;
export const selectOnStageClasses = (state) => state.classes.onStageClasses;

export const selectClassTypes = (state) => state.classes.classTypes;

export default classSlice.reducer;
