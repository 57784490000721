import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  statsList: [],
};
const statsSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {
    setStatsList: (state, action) => {
      state.statsList = action.payload;
    },
  },
});
export const { setStatsList } = statsSlice.actions;

export const selectStatsList = (state) => state.stats.statsList;

export default statsSlice.reducer;
