import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import classNames from "classnames";

// Custom Component
import Layout from "../../components/common/layout/layout";

// Custom CSS
import "../../assets/scss/pages/account/account.scss";
import '../../assets/scss/components/Sidebar/Sidebar.scss';

// Images
import profileIcon from "../../assets/images/Auth/profileIcon.svg";
import toggler from "../../assets/images/Navbar/toggler.svg";
import zap from "../../assets/images/Auth/zap.svg";
import TransactionHistory from '../../assets/images/Sidebar/bank-note-02.png';
import Certificate from '../../assets/images/Sidebar/certificate.png';
import Password from '../../assets/images/Sidebar/lock-01.png';
import Gift from '../../assets/images/Sidebar/gift.png';
import Support from '../../assets/images/Sidebar/info.png';
import Logout from '../../assets/images/Sidebar/log.png';
import Learn from '../../assets/images/Sidebar/learnandearn.png';


// Utility Service
import { removeUserSession } from "../../utils/Auth.Service";

// API Service
import { logout } from "../../services/auth.service";

// Redux-Functions
import { setToken, setUserDetail } from "../../redux/userSlice";
import { useSelector } from "react-redux"

import { subscriptionStatusLearnAndEarn } from "../../services/learnAndEarn.service";
import {  selectLearnAndEarnSubscriptionDetail } from '../../redux/learnAndEarnSubscriptionSlice';
import { selectSubscriptionDetail } from "../../redux/subscriptionSlice";

const Account = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [isCollapsed, setIsCollapsed] = useState(false)

    const handleToggle = () => {
        setIsCollapsed(!isCollapsed);
    };

   


    


    const subscriptionDetail = useSelector(selectLearnAndEarnSubscriptionDetail)
    const subscriptionDetailNormal = useSelector(selectSubscriptionDetail)

    const handleLogoutClick = async () => {
        await logout();
        dispatch(setToken(null));
        dispatch(setUserDetail(null))
        removeUserSession();
        navigate("/");
    };


    return (
        <Layout currentPage="My Account">
            
            <div className="account-page-container">
            <div className="login-out-container-larger-view">
                <h3 className="fw-bold mx-md-5 mx-3 mt-4">{t("myAccount")}</h3>
           
                <button
                    className="navbar-toggler d-block d-md-none mx-3"
                    type="button"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded={!isCollapsed}
                    onClick={handleToggle}
                >
                    <img src={toggler} alt="" />
                </button>
            </div>
                <div className="d-flex flex-column flex-xl-row align-items-start justify-content-between mx-md-5 mx-3 gap-5 mt-4 mt-xl-0">
                    
                    <div className={`collapse w-100 w-xl-25 ${!isCollapsed ? 'show' : ''} account-navbar-collapse login-out-container-larger-view`} id="navbarSupportedContent">
                        <Link to='/account/profile' className={classNames("text-decoration-none d-flex gap-2 align-items-center p-3", {
                            "active-line border-left": location?.pathname === "/account/profile"
                        })}>
                            <img src={profileIcon} alt="icon" />
                            <p className="text-primary m-0 fs-xl-3 fs-6">
                                {t("personalInformation")}
                            </p>
                        </Link>
                        <Link to={(subscriptionDetail?.status === 'active' || subscriptionDetail?.is_enpl || subscriptionDetailNormal?.status === 'active' )? '/account/learn-and-earn/total-points' : "/account/learn-and-earn"} className={classNames("text-decoration-none d-flex gap-2 align-items-center p-3", {
                            "active-line border-left": location?.pathname?.includes("/account/learn-and-earn")
                        })}>
                            <img src={Learn} alt="icon" />
                            <p className="text-primary m-0 fs-xl-3 fs-6">
                                {t("learnAndEarn")}
                            </p>
                        </Link>
                        <Link to='/account/subscription' className={classNames("text-decoration-none d-flex gap-2 align-items-center p-3", {
                            "active-line border-left": location?.pathname === "/account/subscription"
                        })}>
                            <img src={zap} alt="icon" />
                            <p className="text-primary m-0 fs-xl-3 fs-6">
                                {t("subscription")}
                            </p>
                        </Link>
                        <Link to='/account/transaction-history' className={classNames("text-decoration-none d-flex gap-2 align-items-center p-3", {
                            "active-line border-left": location?.pathname === "/account/transaction-history"
                        })}>
                            <img src={TransactionHistory} alt="icon" />
                            <p className="text-primary m-0 fs-xl-3 fs-6">
                                Transaction History
                            </p>
                        </Link>
                        <Link to='/account/certificate' className={classNames("text-decoration-none d-flex gap-2 align-items-center p-3", {
                            "active-line border-left": location?.pathname === "/account/certificate"
                        })}>
                            <img src={Certificate} alt="icon" />
                            <p className="text-primary m-0 fs-xl-3 fs-6">
                                Certificate
                            </p>
                        </Link>
                        <Link to='/account/change-password' className={classNames("text-decoration-none d-flex gap-2 align-items-center p-3", {
                            "active-line border-left": location?.pathname === "/account/change-password"
                        })}>
                            <img src={Password} alt="icon" />
                            <p className="text-primary m-0 fs-xl-3 fs-6">
                                Change Password
                            </p>
                        </Link>
                        <Link to='/redeem' className={classNames("text-decoration-none d-flex gap-2 align-items-center p-3", {
                            "active-line border-left": location?.pathname === "/redeem"
                        })}>
                            <img src={Gift} alt="icon" />
                            <p className="text-primary m-0 fs-xl-3 fs-6">
                                Redeem Gift Card
                            </p>
                        </Link>
                        {/* <Link to='/' className={classNames("text-decoration-none d-flex gap-2 align-items-center p-3", {
                            "active-line border-left": location?.pathname === "/"
                        })}>
                            <img src={Support} alt="icon" />
                            <p className="text-primary m-0 fs-xl-3 fs-6">
                                Support
                            </p>
                        </Link> */}
                        <div role='button' className="text-decoration-none d-flex gap-2 align-items-center p-3" onClick={handleLogoutClick}>
                            <img src={Logout} alt="icon" />
                            <p className="text-primary m-0 fs-xl-3 fs-6">
                                Logout
                            </p>
                        </div>
                    </div>
                    <div className="w-100">
                        {children}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Account;