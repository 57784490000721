import { useState, useEffect, useRef } from "react";
import { Rating } from "react-simple-star-rating";
import { useParams, useNavigate, Link } from "react-router-dom";
import CryptoJS from 'crypto-js';
import moment from "moment";


//importing components
import BannerWithText from "../../components/Banner/BannerWithText";
import ButtonWithArrow from "../../components/common/button/ButtonWithArrow";
import Button from "../../components/common/button/Button";
import NoData from "../../components/common/noData/NoData";
import Image from "../../components/common/image";


//importing images
// import avatar from "../../assets/images/Cards/HorizentalCard/Avatar.svg";
import avatar from "../../assets/images/Auth/avatar2.svg";

import ratingIcon from "../../assets/images/Rating/ratingIcon.svg";
import categoryIcon from "../../assets/images/Onlive/categoryIcon.svg";
import dateIcon from "../../assets/images/Onlive/dateIconn.svg";
import timeIcon from "../../assets/images/Onlive/timeIcon.svg";
import languageIcon from "../../assets/images/Onlive/languageIcon.svg";
import materialIcon from "../../assets/images/Onlive/metarialIcon.svg";
import certificateIcon from "../../assets/images/Onlive/certificateIcon.svg";
import calenderIcon from "../../assets/images/Onlive/calenderIcon.svg";
import fileIcon from "../../assets/images/Onlive/FileIcon.svg";
import viewIcon from "../../assets/images/Onlive/viewIcon.svg";
import downloadIcon from "../../assets/images/Onlive/downloadIcon.svg";
import SegmentImg from "../../assets/images/Onlive/segment.svg";



//importing styles
import "../../assets/scss/pages/onLiveDetailPage/onLiveDetailPage.scss";
import RateNReviewModal from "../../components/Modals/RateNReviewModal";
import ReviewSuccessModal from "../../components/Modals/ReviewSuccessModal";
// import SuccessMessageModal from "../../components/Modals/SuccessMessageModal";
import Layout from "../../components/common/layout/layout";
import { selectLoggedIn, selectToken } from "../../redux/userSlice";
import { useSelector } from "react-redux";
import { classJoinService, classRegistrationService, getClassDetail, generateOtpVideoCipherService, trackClassViewService } from "../../services/class.service";
import { toast } from "react-toastify";
import { getUser } from "../../utils/Auth.Service";
import { setBookMarkService } from "../../services/class.service";
// import { selectLearnAndEarnSubscriptionDetail } from "../../redux/learnAndEarnSubscriptionSlice";
import { selectSubscriptionDetail } from "../../redux/subscriptionSlice";
//Skeleton
import Skeleton from "react-loading-skeleton";
import SecRegisterModal from "../../components/Modals/SecRegisterModal";

const OnLiveDetailPage = () => {
    const { slug } = useParams();
    

    const user = getUser();
    const token = useSelector(selectToken)

    const navigate = useNavigate();
    const login = useSelector(selectLoggedIn);
    const [notFound, setNotFound] = useState(false);
    const [classDetail, setClassDetail] = useState();
    const [videoDetail, setVideoDetail] = useState();
    const [classRegistered, setClassRegistered] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBookMark, setIsLoadingBookMark] = useState(false);

    const [show, setShow] = useState(false);
    const [reviewSuccessModal, setReviewSuccessModal] = useState(false)
    const [buttonText, setButtonText] = useState('Class Ended');
    const iframeRef = useRef(null);
    const videoLink = classDetail?.video_link; // Replace 'videoUrl' with the actual field name
    const videoId = videoLink?.split('/').pop();
    const vimeoUrl = `https://player.vimeo.com/video/${videoId}`;

    const subscriptionDetail = useSelector(selectSubscriptionDetail);
    const [secReg , setSecReg] = useState(false)
    const [secRegClass , setsecRegClass]=useState()
    


    const fetchClassDetail = async () => {
        setNotFound(false)
        const result = await getClassDetail(slug, user?.id);
        if (result?.response?.data?.error === "Class not found.") {
            setNotFound(true)
        } else {
            setClassDetail(result?.data?.class);
        }
    };

    const generateOtpVideoCipher = async () => {
        if (!videoLink?.includes('https://vimeo.com') ) {
            const result = await generateOtpVideoCipherService(classDetail?.video_link);
            setVideoDetail(result?.data)
        }
    };

   

    const handleTrackClassView = async () => {

        const iframe = iframeRef.current;
        if (iframe || !(subscriptionDetail?.status === "active")) {
            if (videoLink?.includes('https://vimeo.com')) {
                // Send play command to Vimeo
                iframe.contentWindow.postMessage({ method: 'play' }, '*');
            } else if (videoDetail !== undefined) {
                // Ensure VdoCipher message is properly formatted
                const message = JSON.stringify({
                    event: "command",
                    func: "playVideo",
                    args: []
                });
                iframe.contentWindow.postMessage(message, iframe.src);
            } else {
                console.warn("Unknown video link format.");
            }
        } else {
            console.warn("Iframe is not available.");
        }
    };


    useEffect(() => {
        fetchClassDetail();
        // eslint-disable-next-line
    }, [classRegistered, slug]);

    useEffect(() => {
        if (classDetail?.video_link && (classDetail?.class_type?.name?.toLocaleLowerCase() === 'onthego' || classDetail?.class_type?.name?.toLocaleLowerCase() === 'onstage' || classDetail?.class_type?.name?.toLocaleLowerCase() === 'onference') ) {
            generateOtpVideoCipher();
        }
        // eslint-disable-next-line
    }, [classDetail]);
    

    useEffect(() => {
        if (classDetail?.registrations?.length > 0 && classDetail?.registrations[0]?.is_joined === 1 && classDetail?.reviews?.length === 0 && moment?.parseZone(date)?.local()?.format()?.replace(/-/g, "") > moment?.parseZone(classDetail?.end_date_time)?.local()?.format()?.replace(/-/g, "")) {
            setShow(true)
        } else {
            setShow(false)
        }
        // eslint-disable-next-line
    }, [classDetail]);

    const handleAddEventToCalender = () => {
        const googleCalendarLink = `http://www.google.com/calendar/event?action=TEMPLATE&text=${classDetail?.title
            }&dates=${moment
                .parseZone(classDetail?.start_date_time)
                .utc() // Convert to UTC
                .format('YYYYMMDDTHHmmss[Z]')}/${moment
                    .parseZone(classDetail?.end_date_time)
                    .utc() // Convert to UTC
                    .format('YYYYMMDDTHHmmss[Z]')}&details=Come and join the class! here's the link for the class, enjoy!  ${`https://reskills.com/class/${classDetail?.slug}` || ""
            }&location=${classDetail?.country?.name || ""}`;
        
        window.open(googleCalendarLink, "_blank");
    };
    

   
    const handleClassRegistration = async (classId) => {
        setLoading(true);
        const result = await classRegistrationService({
            classId: classId,
        });
        if (result?.data?.class_registration?.registration_date) {
            setClassRegistered(true);
            toast.success("Class Registered Successfully.");
        }else if(result?.response?.data?.error === 'You are already registered for a class during this time.'){
            setSecReg(true)
            setsecRegClass(result?.response?.data)
        } 
        
        else {
            toast.error(result?.response?.data?.error || "Class Registration has been closed.");
        }
        setLoading(false);
    };

    const handleClassRegistrations = async (classId) => {
        setIsLoading(true);
        const result = await classRegistrationService({
            classId: classId,
        });
        if (result?.data?.class_registration?.registration_date) {
            setClassRegistered(true);
            toast.success("Class Registered Successfully.");
        } else {
            toast.error(result?.response?.data?.error || "Class Registration has been closed.");
        }
        setIsLoading(false);
    };

    

    const handleJoinClass = async (classId) => {
        const result = await classJoinService({
            classId: classId,
            class_join_date: new Date(),
        });
        if (result?.data?.class_registration?.is_joined) {
            window.location.href = `${process.env.REACT_APP_SUB_DOMAIN}/class/${slug}/${CryptoJS.AES.encrypt(token, 'Reskills').toString().replace(/\+/g, 'p1L2u3S').replace(/\//g, 's1L2a3S4h').replace(/=/g, 'e1Q2u3A4l')}`
            toast.success("User joined class successfully.");
        }
    };

    const [date, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Check every second

        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (classDetail?.end_date_time) {
            const differenceInMinutes = moment().diff(moment.parseZone(classDetail?.end_date_time).local(), 'minutes');
            if (differenceInMinutes > 30) {
                if (videoDetail === undefined && videoLink?.includes('https://') === undefined) {
                    setButtonText('Recording available soon!');
                }
            }
        }
    }, [classDetail?.end_date_time, videoDetail, videoLink]);

    // const date = new Date();
    const currenttTime = moment
        .parseZone(date)
        .local()
        .format()
        .replace(/-/g, "");
    const classStartDate = moment
        .parseZone(classDetail?.start_date_time)
        .local()
        .format()
        .replace(/-/g, "");
    const classEndDate = moment
        .parseZone(classDetail?.end_date_time)
        .local()
        .format()
        .replace(/-/g, "");
    const classJoiningTime = moment
        .parseZone(classDetail?.start_date_time)
        .add(classDetail?.joining_close_after, "minute")
        .local()
        .format()
        .replace(/-/g, "");



    const download = (file, index) => {
        if (file) {
            fetch(file).then(response => {
                response.blob().then(blob => {
                    const fileURL = window.URL.createObjectURL(blob);
                    const alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = `Course_Material_${index + 1}.pdf`;
                    alink.click();
                })
            })
        }
    }

    const setBookMark = async () => {
        setIsLoadingBookMark(true)
        const result = await setBookMarkService({
            class_id: classDetail?.id
        });
        fetchClassDetail();
        if (result?.data?.status === "success") {
            if(classDetail?.is_bookmarked){
                toast.success("Class Bookmark Removed Successfully");
            }
            else{
                toast.success("Class Bookmarked Successfully");
            }
        }
        else if(result?.message==="Request failed with status code 401") {
            toast.error( "Please login to your account to bookmark content");
        }
        else {
            toast.error(result?.response?.data?.error || "Something went wrong");
        }
       
        setIsLoadingBookMark(false)
    }


    const SelectSegments = (segment) => {
        const segments = ['Personal Growth', 'Digital & Tech', '⁠⁠Entrepreneurship'];
        return segments[segment - 1];
    };


   



    // useZoomMeetingRestriction();
    return (
        <Layout>
            {notFound ?
                <div className="card-container w-100">
                    <NoData page="not-found" />
                </div>
                :
                <div className="onLiveDetailPage-main-container custom-container max-w-home">
                    {videoDetail || videoLink?.includes('https://vimeo.com') ? (((classDetail?.class_type.name === "OnTheGo" || classDetail?.class_type.name?.toLowerCase() === "onstage" || classDetail?.class_type.name?.toLowerCase() === "onference" )&& ((classDetail?.premium && !(subscriptionDetail?.status === "active")) || !login))) ?
                        <div className="container my-4 position-relative">
                        <iframe  className="w-100 px-3 vh-80"  />

                            <div
                                className="position-absolute w-100 h-100 top-0 start-0  bg-opacity-75 d-flex align-items-center justify-content-center alert alert-warning "
                                style={{ cursor: 'pointer' }}
                            >
                                <p className="text-center fw-bold"> This content is only accessible with a premium subscription</p>
                            </div>

                        </div> :

                        <iframe ref={iframeRef} title={classDetail?.title} className="w-100 px-3 vh-80" src={videoLink?.includes('https://vimeo.com') ? vimeoUrl : `https://player.vdocipher.com/v2/?otp=${videoDetail?.otp}&playbackInfo=${videoDetail?.playbackInfo}`} allowFullScreen={true} allow={"autoplay; encrypted-media"} frameBorder={0} />
                        :
                        <div onClick={() => !login && navigate("/login")}>

                            <BannerWithText
                                title={classDetail?.title}
                                btnType={isLoading ? "Loading..." : "Register Now"}
                                image={classDetail?.images[0]?.image}
                                handleClassRegistration={handleClassRegistrations}
                                id={classDetail?.id}
                                classEndDate={classEndDate}
                                currenttTime={currenttTime}
                                classDetail={classDetail}
                            />
                        </div>
                    }
                    <h4 className="fw-bold mx-md-5 my-3 mx-3 ">{classDetail?.title || <Skeleton height={35} />}</h4>
                    <div className="onLiveDetailPage-inner-container d-flex gap-5 mx-md-5 my-md-5 mx-3 my-3">
                        {classDetail?.description ?
                            <div
                                className="left-inner-container"
                                dangerouslySetInnerHTML={{
                                    __html: classDetail?.description,
                                }}
                            ></div> :
                            <div className="left-inner-container">
                                <Skeleton count={25} />
                            </div>}
                        <div className="right-inner-container">
                            {classDetail ?
                                <div className="d-flex gap-2">
                                    <p className="text-primary">{classDetail?.class_type?.name}</p>
                                    <div>.</div>
                                    <p className="text-primary">{classDetail?.language?.name}</p>
                                    <div>.</div>
                                    <p className="text-primary">{classDetail?.category?.name}</p>
                                </div> :
                                <div className={` d-flex align-items-center justify-content-between rounded ${classDetail?.category ? 'p-3 border border-secondary bg-white hover-effect' : ''}  `}>
                                    <Skeleton
                                        height={30}
                                        width="100%"
                                        style={{ maxWidth: '400px', width: '100%' }}
                                        containerClassName="skeleton-loader"
                                    />
                                </div>
                            }
                            {classDetail?.registrations?.length > 0 &&
                                currenttTime < classStartDate && (videoDetail === undefined ?
                                    <Button name="Not Started Yet!" btnType="orangeButton" /> :
                                    <Button name="Watch Now" onClick={handleTrackClassView} />
                                )

                            }
                            <div

                                className="my-2"
                            >
                                {classDetail?.registrations?.length === 0 &&
                                    currenttTime < classEndDate &&
                                    (
                                        classDetail?.class_type?.name?.toLowerCase() === 'onthego' ?
                                            <>
                                                <Button name="Watch Now" onClick={handleTrackClassView} />
                                                <div className="my-2">
                                                    <Button name={isLoadingBookMark? "Loading...":classDetail?.is_bookmarked?"Remove Bookmark":"Bookmark"} btnType='white' onClick={setBookMark}
                                                        disable={isLoadingBookMark}
                                                    />
                                                </div>
                                            </>

                                            :
                                            <div
                                                

                                            >
                                                <ButtonWithArrow
                                                    name={loading ? "Loading..." : "Register Now"}
                                                    disabled={loading}
                                                    onClick={() =>
                                                    !login
                                                        ? navigate("/login")
                                                        : handleClassRegistration(classDetail?.id)
                                                }
                                                />
                                            </div>
                                    )}
                            </div>
                            {classDetail?.registrations?.length > 0 &&
                                currenttTime < classJoiningTime &&
                                currenttTime > classStartDate && currenttTime < classEndDate && (
                                    <div >
                                        <ButtonWithArrow
                                            name={loading ? "Loading..." : classJoiningTime > currenttTime ? "Class Started" : "Enter Now"}
                                            onClick={() => handleJoinClass(classDetail?.id)}
                                            disabled={loading}
                                        />
                                    </div>
                                )}
                            {currenttTime > classEndDate && (
                                <div>
                                    {classDetail?.class_type?.name === "OnTheGo" ?
                                        <Button name="Watch Now" onClick={handleTrackClassView} />
                                        : (videoDetail !== undefined || videoLink?.includes('https://vimeo.com')) ?
                                            <Button name="Watch Now" onClick={handleTrackClassView} />

                                            :
                                            <Button
                                                name={buttonText}
                                                btnType="classEnded"
                                            />
                                    }
                                </div>
                            )}
                            {classDetail?.registrations?.length > 0 &&
                                currenttTime > classJoiningTime &&
                                currenttTime < classEndDate && (
                                    classDetail?.registrations[0]?.is_joined === 1 ?
                                        <div >
                                            <ButtonWithArrow
                                                name={loading ? "Loading..." : classJoiningTime > currenttTime ? "Class Started" : "Enter Now"}
                                                onClick={() => handleJoinClass(classDetail?.id)}
                                                disabled={loading}
                                            />
                                        </div> :
                                        <div>
                                            <ButtonWithArrow name="Enter Now" disabled={true} />
                                        </div>


                                )


                            }
                            {classDetail?.coaches?.map((item) => {
                                return (
                                    <div className="border border-secondary rounded p-3 my-4 bg-white">
                                        <h5>Coach</h5>
                                        <div className="d-flex gap-2 align-items-center">
                                            <div
                                                style={{ width: "50px" }}
                                                className="rounded-circle overflow-hidden"
                                            >

                                        <Image
                                            src={item?.avatar}
                                            alt="profile"
                                            style={{ width: "45px", height: "45px" }}
                                            defaultImage={avatar}
                                        />
                                            </div>
                                            <div>
                                                <Link className="text-dark text-decoration-none" to={`/coach/${item?.id}`} >
                                                    <p className="mb-0 ">{item?.full_name}</p>
                                                </Link>
                                                <Rating
                                                    initialValue={classDetail?.average_rating}
                                                    customIcons={ratingIcon}
                                                    readonly={true}
                                                    size={20}
                                                    allowFraction={true}
                                                />{" "}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                            <div className={` d-flex align-items-center justify-content-between rounded ${classDetail?.category ? 'p-3 border border-secondary bg-white hover-effect' : ''} mb-2 `}>
                                {classDetail?.category ?
                                    <>
                                        <div className="d-flex align-items-center gap-2">
                                            <img src={SegmentImg} alt="category-icon" />
                                            <p className="m-0 gray-700">Segment</p>
                                        </div>
                                        <p className="text-secondary m-0">{SelectSegments(classDetail?.segment)}</p>
                                    </> : <Skeleton
                                        height={55}
                                        width="100%"
                                        style={{ maxWidth: '400px', width: '100%' }}
                                        containerClassName="skeleton-loader"
                                    />
                                }


                            </div>
                            <div className={` d-flex align-items-center justify-content-between rounded ${classDetail?.category ? 'p-3 border border-secondary bg-white hover-effect' : ''} mb-2 `}>
                                {classDetail?.category ?
                                    <>
                                        <div className="d-flex align-items-center gap-2">
                                            <img src={categoryIcon} alt="category-icon" />
                                            <p className="m-0 gray-700">Category</p>
                                        </div>
                                        <p className="text-secondary m-0">{classDetail?.category?.name}</p>
                                    </> : <Skeleton
                                        height={55}
                                        width="100%"
                                        style={{ maxWidth: '400px', width: '100%' }}
                                        containerClassName="skeleton-loader"
                                    />
                                }


                            </div>
                            {classDetail?.class_type?.name.toLowerCase() !== 'onthego' &&
                                <div className={` d-flex align-items-center justify-content-between  rounded ${classDetail?.category ? 'p-3 border border-secondary bg-white ' : ''} mb-2 `}>
                                    {classDetail?.category ?
                                        <>
                                            <div className="d-flex align-items-center gap-2">
                                                <img src={dateIcon} alt="category-icon" />
                                                <p className="m-0 gray-700">Date</p>
                                            </div>
                                            <p className="text-secondary m-0">
                                                {classDetail?.start_date_time &&
                                                    moment(classDetail?.start_date_time).format(
                                                        "ddd, Do MMM YYYY"
                                                    )}
                                            </p>
                                        </> : <Skeleton
                                            height={55}
                                            width="100%"
                                            style={{ maxWidth: '400px', width: '100%' }}
                                            containerClassName="skeleton-loader"
                                        />
                                    }
                                </div>
                            }
                            {classDetail?.class_type?.name.toLowerCase() !== 'onthego' &&
                                <div className={` d-flex align-items-center justify-content-between  rounded ${classDetail?.category ? 'p-3 border border-secondary bg-white' : ''} mb-2 `}>
                                    {classDetail?.category ?
                                        <>
                                            <div>
                                                <div className="d-flex align-items-center gap-2">
                                                    <img src={timeIcon} alt="category-icon" />
                                                    <p className="m-0 gray-700">Time</p>
                                                </div>
                                            </div>
                                            <p className="text-secondary m-0">
                                                {classDetail?.start_date_time &&
                                                    moment
                                                        .parseZone(classDetail?.start_date_time)
                                                        .local()
                                                        .format("hh:mm A")}{" "}
                                            </p>
                                        </> : <Skeleton
                                            height={55}
                                            width="100%"
                                            style={{ maxWidth: '400px', width: '100%' }}
                                            containerClassName="skeleton-loader"
                                        />
                                    }

                                </div>}
                            {/* <div className={` d-flex align-items-center justify-content-between  rounded ${classDetail?.category ? 'p-3 border border-secondary bg-white' : ''} mb-2 `}>
                                {classDetail?.category ?
                                    <>
                                        <div className="d-flex align-items-center gap-2">
                                            <img src={timeIcon} alt="category-icon" />
                                            <p className="m-0 gray-700">Duration</p>
                                        </div>
                                        <p className="text-secondary m-0">{classDetail?.duration} mins</p>
                                    </> : <Skeleton
                                        height={55}
                                        width="100%"
                                        style={{ maxWidth: '400px', width: '100%' }}
                                        containerClassName="skeleton-loader"
                                    />
                                }
                            </div> */}
                            <div className={` d-flex align-items-center justify-content-between  rounded ${classDetail?.category ? 'p-3 border border-secondary bg-white' : ''} mb-2 `}>
                                {classDetail?.category ?
                                    <>
                                        <div className="d-flex align-items-center gap-2">
                                            <img src={languageIcon} alt="category-icon" />
                                            <p className="m-0 gray-700">Language</p>
                                        </div>
                                        <p className="text-secondary m-0">{classDetail?.language?.name}</p>
                                    </> : <Skeleton
                                        height={55}
                                        width="100%"
                                        style={{ maxWidth: '400px', width: '100%' }}
                                        containerClassName="skeleton-loader"
                                    />
                                }
                            </div>
                            <div className={` d-flex align-items-center justify-content-between  rounded ${classDetail?.category ? 'p-3 border border-secondary bg-white' : ''} mb-2 `}>
                                {classDetail?.category ?
                                    <>
                                        <div className="d-flex align-items-center w-100 justify-content-between">
                                            <div className="d-flex align-items-center gap-2">
                                                <img src={materialIcon} alt="category-icon" />
                                                <p className="m-0 gray-700">Material</p>
                                            </div>
                                            {!login && (
                                                <div
                                                    className="text-secondary text-end cursor-pointer"
                                                    onClick={() => navigate("/login")}
                                                >
                                                    Login to view
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                        {login &&
                                            classDetail?.files?.map((item, index) => {
                                                return (
                                                    <div className="d-flex align-items-center justify-content-between  mt-3  ">
                                                        <div className="d-flex align-items-center gap-2 mx-3">
                                                            <img src={fileIcon} alt="category-icon" />
                                                            <p className="m-0 gray-700 fs-6 text-truncate">
                                                                Course_Material_{index + 1}.pdf
                                                            </p>
                                                        </div>

                                                        <div className="m-0 d-flex gap-3">
                                                            <img
                                                                src={viewIcon}
                                                                alt="icon"
                                                                onClick={() => window.open(item?.file, "_blank")}
                                                                className="cursor-pointer"

                                                            />
                                                            <img
                                                                src={downloadIcon}
                                                                alt="icon"
                                                                onClick={() => download(item?.file, index)}
                                                                className="cursor-pointer"
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </> : <Skeleton
                                        height={55}
                                        width="100%"
                                        style={{ maxWidth: '400px', width: '100%' }}
                                        containerClassName="skeleton-loader"
                                    />
                                }
                            </div>
                            <div className={` d-flex align-items-center justify-content-between  rounded ${classDetail?.category ? 'p-3 border border-secondary bg-white' : ''} mb-2 `}>
                                {classDetail?.category ?
                                    <>
                                        <div className="d-flex align-items-center gap-2">
                                            <img src={certificateIcon} alt="category-icon" />
                                            <p className="m-0 gray-700">Certificate</p>
                                        </div>
                                        <p className="text-secondary m-0">
                                            {classDetail?.provide_certificate === 1 ? "Yes" : "No"}
                                        </p>
                                    </> : <Skeleton
                                        height={55}
                                        width="100%"
                                        style={{ maxWidth: '400px', width: '100%' }}
                                        containerClassName="skeleton-loader"
                                    />
                                }
                            </div>
                            {classDetail?.category ?
                                <div
                                    className="cursor-pointer d-flex gap-2 justify-content-center mt-4"
                                    onClick={() => handleAddEventToCalender()}
                                >
                                    <img src={calenderIcon} alt="icon" />
                                    <span className="text-primary">Add to calendar</span>
                                </div> :
                                <div className={` d-flex align-items-center justify-content-between  rounded ${classDetail?.category ? 'p-3 border border-secondary bg-white' : ''} mb-2 `}>
                                    <Skeleton
                                        height={55}
                                        width="100%"
                                        style={{ maxWidth: '400px', width: '100%' }}
                                        containerClassName="skeleton-loader"
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            <RateNReviewModal id={classDetail?.id} show={show} setShow={setShow} setReviewSuccessModal={setReviewSuccessModal} />
            <ReviewSuccessModal show={reviewSuccessModal} setShow={setReviewSuccessModal} classType={classDetail?.class_type?.name} />
            <SecRegisterModal show={secReg} setShow={setSecReg} secRegClass={secRegClass}/>
        </Layout>
    );
}

export default OnLiveDetailPage;