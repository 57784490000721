import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscriptionDetail: "",
};
const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setSubscriptionDetail: (state, action) => {
      state.subscriptionDetail = action.payload;
    },
    
  },
});
export const { setSubscriptionDetail} = subscriptionSlice.actions;

export const selectSubscriptionDetail = (state) => state.subscription.subscriptionDetail;

export default subscriptionSlice.reducer;