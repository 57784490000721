import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";

import "../../../assets/scss/components/paginate/paginate.scss";
import NoData from "../noData/NoData";
import TransactionHistoryCard from "../cards/TransactionHistory/TransactionHistoryCard";
import { setTransactionHistory ,selectTransactionHistory } from '../../../redux/transactionHistorySlice';
import { transactionHistoryService } from "../../../services/transaction.service";


function Items({ currentItems }) {
  if (!Array.isArray(currentItems) || currentItems.length === 0) {
    return <NoData />;
  }

  return (
    <>
      
        <div className="card-container">
        {currentItems?.map((item ,index) => {
          return (
            <div>
              <TransactionHistoryCard item={item} index={index} />
            </div>
          );
        })}
      </div>
      
    </>
  );
}

const CardPaginate = () => {
  const dispatch = useDispatch();
  const Classes = useSelector(selectTransactionHistory);

  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItem, setTotalItem] = useState(0);
  const itemsPerPage = 8;

  useEffect(() => {
    const fetchCoaches = async () => {
      const result = await transactionHistoryService({
        perPageItem: itemsPerPage,
        page: currentPage + 1,
      });

      const fetchedHistory = result?.data?.data?.data || [];
      setTotalItem(result?.data?.data?.total || 0);
      dispatch(setTransactionHistory(fetchedHistory));
    };

    fetchCoaches();
  }, [currentPage, dispatch]);

  const pageCount = Math.ceil(totalItem / itemsPerPage);

  const handlePageClick = async (event) => {
    const newOffset = event.selected * itemsPerPage;
    setItemOffset(newOffset);
    setCurrentPage(event.selected);
  };

  return (
    <>
    <div className="">
      <Items currentItems={Classes} />
    </div>
      {pageCount > 1 && (
        <div className="d-flex justify-content-center">
          <ReactPaginate
            breakLabel="..."
            nextLabel={currentPage === pageCount - 1 ? null : "Next"}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={currentPage === 0 ? null : "Previous"}
            renderOnZeroPageCount={null}
            className="paginationComponent"
            containerClassName="pagination"
            previousClassName={currentPage === 0 ? "previous hide-arrow" : "previous"}
            nextClassName={currentPage === pageCount - 1 ? "hide-next-arrow" : "next"}
          />
        </div>
      )}
    </>
  );
};

export default CardPaginate;
