import { useState } from "react";
import { Modal } from "react-bootstrap";
import Lottie from "lottie-react";

//importing components
import Button from "../common/button/Button";

//importing styles
import "../../assets/scss/components/modal/recordingAlertModal.scss";

//importing animation
import animation from "../../assets/animation/Animation.json";
import { useNavigate } from "react-router-dom";

const SuccessMessageModal = ({shows ,setShow}) => {
  // const [show, setShow] = useState(false);

  const navigate = useNavigate()
  const handleClose = () => setShow(false);
  
  return (
    <div className="recordingAlertModal-container">
      {/* <button variant="primary" onClick={handleShow}>
        Launch demo modal
      </button> */}

      <Modal show={shows} onHide={handleClose}>
        <div className="py-4 px-3 text-center">
          <div className="w-50 mx-auto">
            <Lottie animationData={animation} loop={true} />
          </div>
         
            <p className="my-3 fs-5 fw-bold">
              Withdrawal request submitted successfully.
            </p>
          <div
            className="btn-container text-center"
            style={{ width: "70px", margin: "auto" }}
          >
            <Button onClick={()=>{navigate('/account/learn-and-earn/total-points')}} name="Okay" />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SuccessMessageModal;
