//importing styles
import Skeleton from "react-loading-skeleton";
import "../../assets/scss/components/banner/bannerWithText.scss";

const BannerWithText = ({ image }) => {
    return (

        <div className="bannerWithText-main-container px-md-5 px-3 container mb-5">

            <div className="bannerWithText-right-container container">
                {image?
                    <img src={image} alt="img" className="w-100 border-radius  max-img-height"  />
                :
                    <Skeleton
                        height={300}
                    />}
            </div>
        </div>
    );
};

export default BannerWithText;
