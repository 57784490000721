import { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { pdfjs } from 'react-pdf';
import { useSelector } from 'react-redux';

import Navbar from '../common/navbar/Navbar';
import Footer from '../common/footer/Footer';
import ShareModal from '../Modals/ShareModal';

import '../../assets/scss/components/Certificate/Certificate.scss';

import siteLogo from "../../assets/images/new-white-logo.png";

import { generateCertificateService } from '../../services/certificate.service';

import { selectUserDetails } from '../../redux/userSlice';
import { getClassDetail } from '../../services/class.service';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const Certificate = () => {
    const { slug } = useParams();

    const userDetail = useSelector(selectUserDetails);

    const [loading, setLoading] = useState(false);
    const [shareModal, setShareModal] = useState(false);
    const [classDetail, setClassDetail] = useState();
    const [pdf, setPdf] = useState()
    const [pdfImage, setPdfImage] = useState()

    const fetchClassDetail = async () => {
        const result = await getClassDetail(slug, userDetail?.id);
        setClassDetail(result?.data?.class);
    };

    const generateCertificate = async () => {
        setLoading(true)
        const result = await generateCertificateService({
            class_id: classDetail?.id
        });
        setPdf(result?.data?.pdf_url)
        const image = await getPageAsImage(result?.data?.pdf_url, 1);
        setPdfImage(image)
        setLoading(false)
    };

    const getPageAsImage = async (pdfUrl, pageNumber) => {
        const pdf = await pdfjs.getDocument(pdfUrl).promise;
        const page = await pdf.getPage(pageNumber);
        const viewport = page.getViewport({ scale: 2 });

        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const renderContext = {
            canvasContext,
            viewport,
        };

        await page.render(renderContext).promise;
        return canvas.toDataURL('image/png');
    }

    useEffect(() => {
        fetchClassDetail();
        // eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     if (classDetail) {
    //         generateCertificate();
    //     }
    //     // eslint-disable-next-line
    // }, [classDetail]);

    const download = () => {
        if (pdf) {
            fetch(pdf).then(response => {
                response.blob().then(blob => {
                    const fileURL = window.URL.createObjectURL(blob);
                    const alink = document.createElement('a');
                    alink.href = fileURL;
                    alink.download = pdf?.split("/")[pdf?.split("/")?.length - 1];
                    alink.click();
                })
            })
        }
    }

    /**
        * @function handleShareModal
        * @params
        * @description set the visibility of share modal
    */
    const handleShareModal = () => {
        setShareModal(!shareModal)
    }


    return (
        <div>
            <Navbar />
            <div className="half-background d-flex flex-column align-items-center">
                <div className="text-white text-start px-4 header-content">
                    <div className=''>
                        <h2>Well done {`${userDetail?.first_name || ""} ${userDetail?.last_name || ""}`}, You have completed!</h2>
                        <div className='pt-3'>
                            <h5>Great work! You've completed the {classDetail?.title} Course.</h5>
                            <div className="text-right">
                                <p className='mb-0'>Created by</p>
                                <Link className="navbar-brand" to="/">
                                    <img src={siteLogo} alt="site-logo" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h4>Here's your statement of completion!</h4>
                        <p className='py-3'>This can be used as proof of what you've learned and achieved on this course.</p>
                    </div>
                </div>
                <div className="certificate-container d-flex justify-content-center align-items-center rounded px-4 ">
                    {loading ?
                        <Spinner className='mt-5 mx-auto' animation="border" />
                        :
                        <img src={pdfImage} className="img-fluid" />
                    }
                </div>
            </div>
            <div className="my-4 px-4 button-container">
                <button className="btn btn-primary me-2  button primary" onClick={handleShareModal}>Share Certificate</button>
                <button className="btn btn-outline-primary button outline" onClick={download}>Download Certificate</button>
            </div>
            <Footer />

            <ShareModal show={shareModal} handleClose={handleShareModal} shareUrl={pdf} />
        </div>
    );
}

export default Certificate;