import {React ,useEffect} from 'react';
import { useSelector } from 'react-redux';

import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { selectLearnAndEarnSubscriptionDetail } from '../../../redux/learnAndEarnSubscriptionSlice';
import { paymentPageService ,applyScratchCodeService } from '../../../services/learnAndEarn.service';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";


function SummaryModal({show , onHide , activeModal }) {

    const [isChecked, setIsChecked] = useState(false);
    const [showError, setShowError] = useState(false);
    const navigate =useNavigate();

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        setShowError(false); // Hide error when checkbox is checked
    };
    


    
    const subscriptionDetails = useSelector(selectLearnAndEarnSubscriptionDetail);
     

    /**
        * @function handlePayment
        * @params
        * @description used to handle payment
    */
    const handlePayment = async () => {
        if (!isChecked) {
            setShowError(true);
            // toast.error("You must agree to the Terms & Conditions to proceed");
            return;
        }
        const result = await paymentPageService();
        if (result?.data?.data?.respDesc === "Success") {
            window.location.href = result?.data?.data?.webPaymentUrl
        } else {
            toast.error(result?.response?.data?.error || "Your payment is failed")
            // navigate('/account/learn-and-earn')
            
        }
    }


    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header className="px-4 pb-0 border-0" closeButton>
                <Modal.Title>
                    <h3 className="fw-bold">Summary</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            {/* <Form onSubmit={handleSubmit(onSubmit)}> */}
                <div className="w-100 bg-muted rounded">
                    <small className='text-primary fw-semibold'>Online Payment</small>
                    <h1 className='fw-bold'>$26.00</h1>
                    <small className='text-muted'>(Including ${subscriptionDetails?.processing_amount} service charge <br/>fee)</small>
                    <div className='d-flex justify-content-between fs-6 my-2 fw-semibold'>
                        <div>Subtotal</div>
                        <div>${subscriptionDetails?.amount}.00</div>
                    </div>
                    <div className='d-flex justify-content-between fs-6 my-2 fw-semibold'>
                        <div>Processing Service charge</div>
                        <div>${subscriptionDetails?.processing_amount}.00</div>
                    </div>
                    
                    <div className='d-flex justify-content-between  my-2 fw-bold'>
                        <div>Total</div>
                        <div className='text-bold '>$26.00</div>
                    </div>
                    <div className="d-flex gap-2 my-2">
                        <input 
                            type="checkbox"
                            required
                            onChange={handleCheckboxChange}
                        />
                        <small className={showError ? "text-danger" :''}>
                            I agree to the
                            <small className="text-primary mx-1 fw-semibold cursor-pointer" onClick={()=>{navigate('/account/learn-and-earn/term-and-condition')}}>Terms & Conditions</small>
                        </small>
                    </div>
                    {/* {activeModal=== 'summary-scratch-code' &&
                       
                        <>
                            <div className='row'>
                                <div className='col-8'>
                                <input
                                        className="form-control"
                                        type="text"
                                        placeholder="Enter Here"
                                        {...register('scratch_code', {
                                            required: "Scratch code is required"
                                        })}
                                    />
                                </div>
                            <div className='col-4'> 
                                    <button  className='form-control btn btn-primary '>Continue</button>
                            </div>
                            
                            </div>
                            </>
                        
                    } */}
                    
                    
                </div>
            {/* </Form> */}
            </Modal.Body>
           
            <Modal.Footer>
            <div className='d-flex justify-content-end w-100 '>
                        <div className='d-flex  w-50 '>
                            <button  className='form-control  text-primary btn  mx-2 border solid' onClick={onHide}>Cancel</button>
                            <div onClick={handlePayment}>
                                <button  className='form-control btn btn-primary'>Continue</button>
                            </div>
                        </div>
                    </div>
            </Modal.Footer>
            
        </Modal>
    );
}

export default SummaryModal;