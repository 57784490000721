import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    transactionHistory: "",
};
const transactionHistorySlice = createSlice({
  name: "transactionHistoryList",
  initialState,
  reducers: {
    setTransactionHistory: (state, action) => {
      state.transactionHistory = action.payload;
    },
    
  },
});
export const { setTransactionHistory} = transactionHistorySlice.actions;

export const selectTransactionHistory = (state) => state.transactionHistoryList.transactionHistory;

export default transactionHistorySlice.reducer;