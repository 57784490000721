import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  missionList: [],
};

const missionSlice = createSlice({
  name: "mission",
  initialState,
  reducers: {
    setMissionList: (state, action) => {
      state.missionList = action.payload;
    },
  },
});

export const { setMissionList } = missionSlice.actions; // Fix export to match action name

export const selectMissionList = (state) => state.mission.missionList;

export default missionSlice.reducer;
