import { useSelector } from "react-redux";

//importing images
import siteLogo from "../../assets/images/siteLogoReskills.svg";

//importing styles
import "../../assets/scss/components/testimonial/testimonial.scss";
import SiteSlider from "../common/slider/SiteSlider";
import { selectReviewList } from "../../redux/reviewSlice";
import Skeleton from "react-loading-skeleton";

const Testimonial = () => {
  const review = useSelector(selectReviewList)?.filter((item) => {
    return item.highlight === 1;
  });

  return (
    <div className="testimonial-main-container">
      <SiteSlider noOfItems={review?.length}>
        {review?.map((item, index) => {
          return (
            <div className="testimonial-Inner-container text-center py-3" key={`review-${index}`}>
              <img src={siteLogo} alt="sitelogo" />

              <h3 className="my-4 fw-bold mx-5">{item?.comment || <Skeleton width={200} />}</h3>
              <div style={{ width: "70px" }} className="text-center mx-auto ">
                {item?.image ?
                  <img
                    src={item?.image}
                    alt="profileIcon"
                    className="mb-3 rounded-circle overflow-hidden"
                    style={{ width: "80px", height: "80px" }}
                  /> : <Skeleton circle height={80} width={80} />
                }
              </div>
              <p className="m-0 fw-bold fs-5">{item?.name || <Skeleton width={200} />}</p>
              <p className="m-0 text-secondary fw-semibold">{item?.profession || <Skeleton width={200} />}</p>
            </div>
          );
        })}
      </SiteSlider>
    </div>
  );
};

export default Testimonial;
