import { Modal } from "react-bootstrap";
import Lottie from "lottie-react";
import { useParams, Link } from "react-router-dom";

//importing components
import Button from "../common/button/Button";

//importing styles
import "../../assets/scss/components/modal/recordingAlertModal.scss";

//importing animation
import animation from "../../assets/animation/Animation.json";

const ReviewSuccessModal = ({ show, setShow, classType }) => {

  const { slug } = useParams();

  const handleClose = () => setShow(false);

  return (
    <Modal show={show} onHide={handleClose}>
      <div className="py-4 px-3">
        <div className="w-50 mx-auto">
          <Lottie animationData={animation} loop={true} />
        </div>
        <div className="my-4">
          <p className="fs-24 text-center mb-1 fw-bold">Your Ratings and Review has been submitted successfully</p>
        </div>
        <Link className="text-decoration-none" to={classType?.toLowerCase() === "onlive" ? `/assessment/${slug}` : `/my-learning`}>
          <div
            className="btn-container text-center"
            style={{ width: "80px", margin: "auto" }}
          >
            <Button name="Continue" />
          </div>
        </Link>
      </div>
    </Modal>
  );
};

export default ReviewSuccessModal;
