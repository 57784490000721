import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

//importing components
import Button from "../../common/button/Button";

//importing style
import "../../../assets/scss/components/course/learnAnytimeClassListing/learnAnytimeClassListing.scss";
import SiteSlider from "../../common/slider/SiteSlider";
import {
  selectClassList,
  selectClassTypes,
  selectOnTheGoClasses,
  setOnTheGoClasses,
} from "../../../redux/classSlice";
import VerticalCardForLearnAnytime from "../../common/cards/courseCards/VerticalCardForLearnAnytime";
import { getClassService } from "../../../services/dashboard.service";

const LearnAnytimeClassListing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getSlug = useSelector(selectClassTypes)?.filter((item) => {
    return item?.name?.toLowerCase() === "onthego";
  });
  useEffect(() => {
    const fetchOnTheGoClasses = async () => {
      try {
        const result = await getClassService({ filter: getSlug[0]?.slug });
        dispatch(setOnTheGoClasses(result?.data?.classes?.data));
      } catch (error) {
        console.log(error);
      }
    };
    if (getSlug[0]?.slug) {
      fetchOnTheGoClasses();
    }
  }, [getSlug[0]?.slug]);
  const OntheGoClasses = useSelector(selectOnTheGoClasses)?.filter(
    (item) => item
  );
  // const OntheGoClasses = useSelector(selectOnTheGoClasses);
  return (
    <>
      {OntheGoClasses?.length > 0 && (
        <div className="LearnAnytimeClassListing-main-container">
          <h2 className="fw-bold fs-4 mb-1 mx-2">OnTheGo</h2>
          <div className="d-flex align-items-center justify-content-between flex-wrap mx-2">
            <p className="LearnAnytimeClassListing-main-text">
              Learn Anytime, Anywhere
            </p>
            <Link to={`/classes/${getSlug[0]?.slug}`}>
              <div
                className="my-2"
              >
                <Button name="Explore All Courses" btnType="whiteButton" />
              </div>
            </Link>
          </div>
          <div className="mt-2">
            <SiteSlider cardType="vertical" noOfItems={OntheGoClasses?.length}>
              {OntheGoClasses?.map((item, index) => {
                return (
                  <div
                    className="m-2"
                    // onClick={() => navigate(`/class/${item?.slug}`)}
                    key={`onthego-${index}`}
                  >
                    <VerticalCardForLearnAnytime
                      cardContent={item}
                      cardType="learnAnytime"
                    />
                  </div>
                );
              })}
            </SiteSlider>
          </div>
        </div>
      )}
    </>
  );
};

export default LearnAnytimeClassListing;
