import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    learnAndEarnSubscriptionDetail: "",
};
const learnAndEarnSubscriptionSlice = createSlice({
  name: "learnAndEarnSubscription",
  initialState,
  reducers: {
    setLearnAndEarnSubscriptionDetail: (state, action) => {
      state.learnAndEarnSubscriptionDetail = action.payload;
    },
    
  },
});
export const { setLearnAndEarnSubscriptionDetail} = learnAndEarnSubscriptionSlice.actions;

export const selectLearnAndEarnSubscriptionDetail = (state) => state.learnAndEarnSubscription.learnAndEarnSubscriptionDetail;

export default learnAndEarnSubscriptionSlice.reducer;