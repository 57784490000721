import React from 'react';
import TransactionHistory from '../../assets/images/TransactionHistory/Icon.png';


const NoTransactionHistory = () => {
    return (
        <div className='shadow-sm rounded  p-3 border border-light content-between w-100  row  justify-content-center align-items-center p-5'>
            <img src={TransactionHistory} className='my-4' style={{width:"100px"}}/>
            <h4 className='fw-bold text-center'>No Transaction History</h4>
            <h5 className='  fw-semibold text-center'>You haven’t done any transaction yet!</h5>
        </div>
    );
};

export default NoTransactionHistory;