import axios from "axios";

/**
    * @function paymentPage
    * @param
    * @description This function is used to make the payment
*/
export const paymentPageService = async () => {
    try {
        const result = await axios.post(`user/learn-and-earn/subscribe/payment`, {});
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function applyScratchCodeService
    * @param promo_code
    * @description This function is used to apply promo code
*/
export const applyScratchCodeService = async (scratch_code) => {
    
    try {
        const result = await axios.post(`user/learn-and-earn/subscribe/scratch-code`, {
            scratch_code
        });
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function subscriptionStatus
    * @param
    * @description This function is used to fetch the status of user's subscription
*/
export const subscriptionStatusLearnAndEarn = async () => {
    try {
        const result = await axios.get(`user/learn-and-earn/subscription-status`, {});
        return result;
    } catch (error) {
        return error;
    }
};


/**
    * @function claimRewardService

    * @description This function is used to claim reward from Learn and Earn program
*/
export const claimRewardService = async (
    {
        withdraw_points,
        official_document_type,
            document_file,
            document_file_second ,
            full_name,
            email,
            phone_number,
            address,
            tax_id,
            bank_country,
            bank_name,
            bank_account_number,
            bank_account_holder_name,
            swift_code,
            has_reskills_user,
            terms_and_conditions_accepted,
            
    }) => {
    
    try {
        const result = await axios.post(`user/withdraw-points`, {
            withdraw_points,
            official_document_type,
            document_file,
            document_file_second ,
            full_name,
            email,
            phone_number,
            address,
            tax_id,
            bank_country,
            bank_name,
            bank_account_number,
            bank_account_holder_name,
            swift_code,
            has_reskills_user,
            terms_and_conditions_accepted,
            
        }, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function getMissions
    * @param
    * @description This function is used to fetch the Mission 
*/
export const getMissions = async ({page='' , per_page='8'}) => {
    try {
        const result = await axios.get(`user/missions?${ page && "&page=" +page}${ per_page && "&per_page=" +per_page}`);
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function missionShow
    * @param
    * @description This function is used to fetch the Mission 
*/
export const missionShow = async (id) => {
    try {
        const result = await axios.get(`user/mission-show/${id}`);
        return result;
    } catch (error) {
        return error;
    }
};


/**
  * @function submitMission
  * @param id
  * @param formData
  * @description This function is used to submit the mission form
  */
export const submitMission = async (id, formData) => {
    try {
      // Create a FormData instance
      const formattedData = new FormData();
  
      // Loop through the inputs and append to FormData
      formData?.inputs?.map((input, index) => {
        formattedData?.append(`inputs[${index}][mission_input_id]`, input[`inputs[${index}][mission_input_id]`]);
        formattedData?.append(`inputs[${index}][value]`, input[`inputs[${index}][value]`]);
      });
  
      // Send the FormData
      const result = await axios.post(`user/mission-submit/${id}`, formattedData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Ensure it's set to multipart
        },
      });
  
      return result;
    } catch (error) {
      return error;
    }
  };
  
  /**
    * @function getMissionBegin
    * @param
    * @description This function is used to fetch the mission begin
*/
export const getMissionBegin = async (id) => {
    try {
        const result = await axios.get(`user/mission-begin/${id}`);
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function missionStatus
    * @param
    * @description This function is used to fetch the mission Status 
*/
export const missionStatus = async (id) => {
    try {
        const result = await axios.get(`user/check-mission-status/${id}`);
        return result;
    } catch (error) {
        return error;
    }
};