import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
//importing components
import VerticalCard from "../../common/cards/courseCards/VerticalCard";
import Button from "../../common/button/Button";

//importing style
import "../../../assets/scss/components/course/onstageClassListing/onstageClassListing.scss";
import SiteSlider from "../../common/slider/SiteSlider";
import {
  selectClassTypes,
  selectOnStageClasses,
  setOnStageClasses,
} from "../../../redux/classSlice";
import { getClassService } from "../../../services/dashboard.service";

const OnStageClassListing = () => {
  const dispatch = useDispatch();
  const getSlug = useSelector(selectClassTypes)?.filter((item) => {
    return item?.name?.toLowerCase() === "onstage";
  });

  useEffect(() => {
    const fetchOnStageClasses = async () => {
      try {
        const result = await getClassService({ filter: getSlug[0]?.slug });
        const currentDateTime = new Date();

          const futureDates = result?.data?.classes?.data
            ?.filter(item => {
              const itemDateTime = moment.utc(item?.start_date_time);

              return itemDateTime > currentDateTime;
            })
            ?.sort(
              (a, b) =>
                new Date(a.start_date_time) - new Date(b.start_date_time),
            );

          const pastDates =result?.data?.classes?.data
            ?.filter(item => {
              const itemDateTime = moment.utc(item.start_date_time);
              return itemDateTime < currentDateTime;
            })
            ?.sort((a, b) => a.start_date_time - b.start_date_time);

          // setList([...futureDates, ...pastDates]);
        dispatch(setOnStageClasses([...futureDates, ...pastDates]));
      } catch (error) {
        console.log(error);
      }
    };
    if (getSlug[0]?.slug) {
      fetchOnStageClasses();
    }
    // eslint-disable-next-line
  }, [getSlug[0]?.slug]);
  const onStageClasses = useSelector(selectOnStageClasses)
  // const onStageClasses = useSelector(selectOnStageClasses);
  return (
    <>
      {onStageClasses?.length > 0 && (
        <div className="OnStageClassListing-main-container">
          <h2 className="fw-bold fs-4 mb-1 mx-2">OnStage</h2>
          <div className="d-flex align-items-center justify-content-between flex-wrap mx-2">
            <p className="OnStageClassListing-main-text my-2">
              Exclusive sessions with industry leaders and famous personalities.
            </p>
            <Link to={`/classes/${getSlug[0]?.slug}`}>
              <div
                className="my-2"
              >
                <Button name="Explore All Courses" btnType="whiteButton" />
              </div>
            </Link>
          </div>
          <div className="mt-2">
            <SiteSlider cardType="vertical" noOfItems={onStageClasses?.length}>
              {onStageClasses?.map((item, index) => {
                return (
                  <div className="m-2" key={`onstage-${index}`}>
                    <VerticalCard cardContent={item} cardType="onStageCard" />
                  </div>
                );
              })}
            </SiteSlider>
          </div>
        </div>
      )}
    </>
  );
};

export default OnStageClassListing;