import React, { useState, useEffect } from "react";
import Account from "../account/Account";
import { useNavigate, useLocation } from "react-router-dom";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { toast } from "react-toastify";

// Image Import

import Cover from '../../assets/images/LearnAndEarn/main-fram-point.png'
import EnplCover from '../../assets/images/LearnAndEarn/enpl.png'
import Smallicon from '../../assets/images/LearnAndEarn/reskillroundimage.png'
import ticket from '../../assets/images/LearnAndEarn/ticket-02.png'
import share from '../../assets/images/LearnAndEarn/Icon.png'
import star from '../../assets/images/LearnAndEarn/star.png'
import book from '../../assets/images/LearnAndEarn/book-open-02.png'


// Import Css

import "../../assets/scss/pages/learnAndEarn/learnAndEarn.scss";
import ButtonWithArrow from "../../components/common/button/ButtonWithArrow";
import Button from "../../components/common/button/Button";
import ShareModal from "../../components/Modals/ShareModal";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

//
import {
  getMissions,
  missionShow,
  subscriptionStatusLearnAndEarn,
  getMissionBegin
} from "../../services/learnAndEarn.service";
import { useDispatch, useSelector } from "react-redux";
import {
  setLearnAndEarnSubscriptionDetail,
  selectLearnAndEarnSubscriptionDetail,
} from "../../redux/learnAndEarnSubscriptionSlice";
import SubscribeModal from "../../components/Modals/RateAndReviewModals/SubscribeModal";
import MissionPaginate from "../../components/common/paginate/MissionPaginate";
import { setMissionList } from "../../redux/missionSlice";
import RewardModal from "../../components/Modals/RateAndReviewModals/SubscribeModal";

const LearnAndEarnTotalPoints = () => {
  const [shareModal, setShareModal] = useState(false);
  const [rewardModal, setRewardModal] = useState(false);
  const [mission, setMission] = useState([]);
  const [missionDetail , setMissionDetail] = useState()
  const [begin , setBegin] = useState([])
  const [isLoading , setLoading] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const subscriptionDetail = useSelector(selectLearnAndEarnSubscriptionDetail);


  const fetchMission = async (page_no) => {
    try {
      const result = await getMissions({
        page: page_no ? page_no :1 
      });
      dispatch(setMissionList(result?.data?.data || []));
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMissionBeginButton = async (id ,page_no) => {
    setLoading(true);
    try {
      const result = await getMissionBegin(id);
      if (result?.data?.message === "Mission begun successfully.") {
        fetchMission(page_no);
      }
      if (result?.data?.message) {
        toast.success(result?.data?.message);
      } else {
        toast.error(result?.response?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fetchMissionShow = async (id) => {
    setLoading(true);
    try {
      const result = await missionShow(id);
      setMissionDetail(result?.data?.mission);
      setRewardModal(true);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
      fetchMission();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status");

    if (status === "failure") {
      navigate("/account/learn-and-earn");
    }
  }, [location, navigate]);

  const handleShareModal = () => {
    setShareModal(!shareModal);
  };

  const handleRewardModal = () => {
    setRewardModal(!rewardModal);
  };

  return (
    <Account>
    <div className='tran-container d-flex  justify-content-center'>
        <div className='main-tr-container'>
            <h4 className=' fw-bold'>ReSkills Learn & Earn Program</h4>
            <div className='shadow-sm rounded my-4 border border-light  flex-column p-3 bg-card-primary'>
                <img src={Smallicon} style={{ height: "30px", width: "30px" }} alt='logo' />
                <div className='d-flex justify-content-between'>
                    <div className='fw-semibold mx-2 text-white'>Total Earning Points (1 point = $1)</div>
                    <div>
                    <OverlayTrigger
                                placement="top"
                                style={{width:"300px"}}
                                overlay={
                                    <Tooltip id="tooltip-top">
                                    <div style={{width:"300px"}}>
                                        Starting October 15th 2024, participants can request for a withdrawal once they have accumulated a minimum of 50 points.
                                        Only one withdrawal request can be submitted at a time. Please wait until after your initial request has been approved or rejected before submitting another request.  
                                    </div>
                                      
                                    </Tooltip>
                                }
                            >
                                <div className='text-white semibold m-2'>
                                    <IoIosInformationCircleOutline size={20} />
                                </div>
                            </OverlayTrigger>
                    </div>
                </div>
                <div className='mx-2 d-flex justify-content-between'>
                    <div className='fs-4 fw-bold text-white'>{ ( subscriptionDetail?.is_enpl? Math.floor(subscriptionDetail?.points_from_assessment):Math.round(subscriptionDetail?.total_points * 100) / 100) || 0}</div>
                    {subscriptionDetail?.total_points >= 50 && (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                subscriptionDetail?.withdraw_status === "pending" ? (
                                    <Tooltip id="tooltip-top">
                                        Only one withdrawal request can be submitted at a time. Please wait until your request is approved or rejected.
                                    </Tooltip>
                                ) : (
                                    <></>
                                )
                            }
                        >
                            <div className='sub-btn-l' onClick={() => navigate(subscriptionDetail?.withdraw_status === "pending" ? '' :'/account/learn-and-earn/beneficiary-details')}>
                                <ButtonWithArrow
                                    name="Claim Reward"
                                    type="whitebtn"
                                    disabled={subscriptionDetail?.withdraw_status === "pending"}

                                />
                            </div>
                        </OverlayTrigger>
                    )}

                   

                </div>
            </div>

            <div className='shadow-sm rounded border border-light w-100 main-banner'>
                <a href="http://About.reskills.com/learn-and-earn-program-details/" target="_blank" rel="noopener noreferrer">
                    <img src={subscriptionDetail?.is_enpl ? EnplCover : Cover} className='img-fluid' style={{ maxHeight: '280px' }} alt="Learn and Earn Program Details" />
                </a>
            </div>
           {!(subscriptionDetail?.is_enpl) && <div className='shadow-sm rounded my-3 border border-light  p-3'>
                <div className='d-flex justify-content-between'>
                    <div className='fw-semibold text-primary'><img src={ticket} className='mx-1' alt='ticket' style={{ height: "20px", marginBottom: "2px" }} />Referral Rewards</div>
                    <div className='fw-semibold text-primary'>{Math.floor(subscriptionDetail?.referral_count)} Referrals</div>
                </div>
                <small className='text-muted mx-1'>For each successful referral with Learn & Earn (2 year) subscription, you get 2.4 Points (10% of the value in points) </small>
                <div className='bg-muted rounded my-3'>
                    <div className='fw-semibold fw-semibold'>Be an inspiration to your friends! Invite them to Learn and Earn with ReSkills now!</div>
                    <div className='my-3 d-flex justify-content-between'>
                        <div className='fw-semibold text-primary'>{subscriptionDetail?.referral_code_url}</div>
                        <div onClick={handleShareModal}>
                            <Button
                                name={"Share"}
                                icon={share}

                            />

                        </div>

                    </div>
                </div>
            </div>}
            <div className='shadow-sm rounded my-3 border border-light  p-3'>
                <div className='d-flex justify-content-between'>
                    <div className='fw-semibold text-primary'><img src={book} className='mx-1' alt='ticket' style={{ height: "20px", marginBottom: "2px" }} />Learning Rewards</div>
                    <div className='fw-semibold text-primary'>{Math.floor(subscriptionDetail?.points_from_assessment)} / {subscriptionDetail?.is_enpl ?'50':'100'} points</div>
                </div>
                <small className='text-muted mx-1'>For each onLive certificate you successfully obtain, you can earn 1 Point.(max of {subscriptionDetail?.is_enpl ?'50':'100'} Points) </small>
            </div>
            
          
          {/* Mission Reward */}

          {!(subscriptionDetail?.is_enpl) &&
            <div className="shadow-sm rounded my-3 border border-light  p-3">
            <div className="d-flex justify-content-between">
              <div className="fw-semibold  text-primary ">
                <img
                  src={star}
                  className="mx-1"
                  alt="ticket"
                  style={{ height: "20px", marginBottom: "2px" }}
                />
                Mission Rewards
              </div>
              <div className="fw-semibold text-primary">{subscriptionDetail?.points_from_mission} / 100 points</div>
            </div>
            <small className="text-muted mx-1">
            For each mission task you successfully complete, you can earn points allocated for completing that task. (max. of 100 Points){' '} 
            </small>
              <MissionPaginate
                isLoading={isLoading}
                fetchMissionShow={fetchMissionShow}
                fetchMissionBeginButton={fetchMissionBeginButton}
                
              />

          </div>}
        </div>
      </div>
      <ShareModal
        show={shareModal}
        handleClose={handleShareModal}
        shareUrl={subscriptionDetail?.referral_code_url}
      />
      <RewardModal 
        show={rewardModal} 
        handleClose={handleRewardModal}
        missionDetail={missionDetail}
      />
    </Account>
  );
};

export default LearnAndEarnTotalPoints;
