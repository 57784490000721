import { useState } from "react";

//importing styles
import "../../../assets/scss/components/input/input.scss";

//importing icons
import eyeoff from "../../../assets/images/Auth/eyeoff.svg";
import eyeon from "../../../assets/images/Auth/eyeon.svg";

const Input = ({ name, placeholder, type, onChange, errorClass, defaultValue ,disabled }) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="input-container">
      {type !== "password" ? (
        <div className="input-group">
          <input
            name={name}
            onChange={onChange}
            type="text"
            className={`${errorClass} form-control`}
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            placeholder={placeholder}
            defaultValue={defaultValue}
            disabled={disabled}
          />
        </div>
      ) : (
        <div className="input-password-group">
          <input
            name={name}
            type={showPassword ? "text" : "password"}
            className={`${errorClass} form-control`}
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            placeholder={placeholder}
            onChange={onChange}
          />
          <div className="input-password-icon-container">
            {!showPassword ? (
              <img
                src={eyeoff}
                alt="eyeoffIcon"
                onClick={() => setShowPassword(!showPassword)}
              />
            ) : (
              <img
                src={eyeon}
                alt="eyeonIcon"
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Input;
