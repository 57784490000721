import axios from "axios";

/**
    * @function getContentService
    * @param slug
    * @description This function is used to get content
*/
export const getContentService = async (slug) => {
    try {
        const result = await axios.get(`user/content/${slug}`);
        return result;
    } catch (error) {
        return error;
    }
}
