import { useSelector ,useDispatch } from "react-redux";
//importing components
import Button from "../common/button/Button";
import { Link } from "react-router-dom";

//importing styles
import "../../assets/scss/components/featuredCategory/featuredCategory.scss";
import CategoryCard from "../common/cards/categoryCard/CategoryCard";
import { getFeaturedCategories } from "../../services/category.service";



import { useEffect, useState } from "react";


const FeaturedCategory = () => {
    
    // const dispatch = useDispatch();
    const [category ,setCategory] = useState()
     /**
        * @function fetchCategory
        * @param
        * @description This function is used fetch category List
    */
     const fetchCategory = async () => {
        try {
            const result = await getFeaturedCategories({
                highlight:true
            });
            // dispatch(setCategoryList(result?.data?.categories));
            setCategory(result?.data?.categories)
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(()=>{
        fetchCategory();
    },[])

    // const categories = useSelector(selectCategoryList);


    return <div className="">
        <div className="FeaturedCategory-main-container">
            <h2 className="fw-bolder fs-4 mb-2">Courses Categories</h2>
            <div className="d-flex align-items-center justify-content-between flex-wrap ">
                <p className="FeaturedCategory-main-text">Explore ReSkills Courses</p>

                <Link to={`/all-category`}>
                    <div className="my-2">
                        <Button name="View All" btnType="whiteButton" />
                    </div>
                </Link>
            </div>
            <div className="mt-2 w-100">
                <div className="FeaturedCategory-card-container row w-100">
                    {category?.filter((item,index)=>index<=8)?.map((item, index) => {
                        return (
                            <div className="col-4" key={index}>
                                <CategoryCard
                                    cardDetail={item}
                                    cardType="cardWithCheckHover"
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    </div>
};

export default FeaturedCategory;
