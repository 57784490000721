import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

//importing image
import icon from "../../../assets/images/Breadcrumb.svg"
import slash from "../../../assets/images/backslash.svg"
import { Link } from "react-router-dom";

const Layout = ({ children, currentPage }) => {
    return <div >
        <Navbar />
        <div className="custom-container max-w-home">
        
        {
            currentPage &&currentPage !=='Search' &&
                <div className="login-out-container-larger-view">
                <div className="d-flex gap-3 mx-md-5 mx-3 mt-3 align-items-center ">
                    <Link to={'/'}>
                        <img src={icon} alt="icon" /> 
                    </Link>
                    <img src={slash} alt="icon" /><span className="text-primary px-2 py-1 rounded">{currentPage}</span>
                </div>
                </div>
        }
        <div className="custom-container max-w-home">
            {children}
        </div>
        </div>
        <Footer />
    </div>
}

export default Layout;
