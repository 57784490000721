import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

//importing image
import searchIcon from "../../assets/images/Onlive/searchIcon2.svg";

//importing redux
import Layout from "../../components/common/layout/layout";
import { Tab, Tabs } from "react-bootstrap";
import { selectCategoryList } from "../../redux/categorySlice";
import { setRegisteredClassList, selectRegisteredClassList, setJoinedClassList, selectJoinedClassList, setBookmarkClassList, selectBookmarkClassList } from "../../redux/classSlice";
import { getBookmarkListService, getJoinedClassListService, getRegisteredClassListService } from "../../services/learning.service";
import VerticalCard from "../../components/common/cards/courseCards/VerticalCard";
import NoData from "../../components/common/noData/NoData";
import MyLearningPaginate from "../../components/common/paginate/MyLearningPaginate";

const MyLearning = () => {
    const dispatch = useDispatch();
    const registeredClasses = useSelector(selectRegisteredClassList);
    const joinedClasses = useSelector(selectJoinedClassList);
    const bookmarkClasses = useSelector(selectBookmarkClassList);
    const categories = useSelector(selectCategoryList);

    const [key, setKey] = useState('previous-learnings');
    const [selectedCategory, setSelectedCategory] = useState();
    const [searchClass, setSearchClass] = useState();



    // const badges = ['OnLive', 'OnStage', 'OnFerence'];
    const badges = [
        {
            name: 'OnLive',
            slug: 'onlive'
        },
        {
            name: 'OnStage',
            slug: 'onstage'
        },
        {
            name: 'OnFerence',
            slug: 'onference'
        }
    ]

    const [activeBadge, setActiveBadge] = useState('onlive');

    const handleBadgeClick = (badge) => {
        setActiveBadge(badge?.slug);
    };

    const handleSearch = async (event) => {
        const { value } = event?.target;
        setSearchClass(value)
        // if (key === "previous-learnings") {
        //     fetchJoinedClasses(value)
        // }
        // if (key === "my-registrations") {
        //     fetchRegisteredClasses(value);
        // }
        // if (key === "my-playlist") {
        //     fetchBookmarkClasses(value)
        // }
    };


    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 500);
        };
    };

    // eslint-disable-next-line
    const optimisedVersion = useCallback(debounce(handleSearch), []);


    // const fetchJoinedClasses = async (searchQuery) => {
    //     try {
    //         const result = await getJoinedClassListService({
    //             perPageItem: 9,
    //             page: 1,
    //             category: selectedCategory,
    //             searchQuery,
    //             tab: activeBadge
    //         });
    //         console.log("result", result);
    //         dispatch(setJoinedClassList(result));

    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // const fetchRegisteredClasses = async (searchQuery) => {
    //     try {
    //         const result = await getRegisteredClassListService({
    //             perPageItem: 9,
    //             page: 1,
    //             category: selectedCategory,
    //             searchQuery,
    //             tab: activeBadge
    //         });
    //         dispatch(setRegisteredClassList(result));
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // const fetchBookmarkClasses = async (searchQuery) => {
    //     try {
    //         const result = await getBookmarkListService({
    //             perPageItem: 9,
    //             page: 1,
    //             category: selectedCategory,
    //             searchQuery
    //         });
    //         dispatch(setBookmarkClassList(result?.data?.bookmarked_classes));
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // useEffect(() => {
    //     if (key === "previous-learnings") {
    //         fetchJoinedClasses()
    //     }
    //     if (key === "my-registrations") {
    //         fetchRegisteredClasses();
    //     }
    //     if (key === "my-playlist") {
    //         fetchBookmarkClasses()
    //     }
    //     // eslint-disable-next-line
    // }, [key, selectedCategory, activeBadge]);

    return (
        <Layout currentPage="My Learnings">
            <div className="custom-container max-w-home">
                <div className="d-flex flex-column gap-3 mx-md-5 mx-3 mt-3 align-items-start my-learning-tab">
                    <p className="fs-30 fw-bold">My Learnings</p>
                    <Tabs
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="w-100"
                    >
                        <Tab eventKey="previous-learnings" title="Previous Learnings">
                        </Tab>
                        <Tab eventKey="my-registrations" title="My Registrations">
                        </Tab>
                        <Tab eventKey="my-playlist" title="My Playlist">
                        </Tab>
                    </Tabs>
                    <div className="w-100 onlive-filter-container d-flex align-items-center justify-content-between  mb-4">
                        <div className="search-container rounded">
                            <img src={searchIcon} alt="icon" />
                            <input
                                className="form-control px-5"
                                type="search"
                                placeholder="Search title"
                                aria-label="Search"
                                onChange={optimisedVersion}
                            />
                        </div>
                        <div className="dropdown-container">
                            <select
                                className="dropdown-itemMenu"
                                onChange={(e) => setSelectedCategory(e.target.value)}
                            >
                                <option>Select Category</option>
                                {categories?.map((item) => {
                                    return (
                                        <option className="cursor-pointer" value={item?.slug}>
                                            {item?.name}
                                        </option>
                                    );
                                })}
                                {/* <option className="dropdown-items">Eng</option>
                    <option className="dropdown-items">Malay</option> */}
                            </select>
                        </div>
                    </div>
                    {(key === "my-registrations" || key === "previous-learnings") && badges.length > 0 && (
                        <div className="d-flex">
                            {badges.map((badge) => (
                                <div
                                    key={badge}
                                    className={`badge rounded-pill px-4 py-3 fs-6 mx-2 cursor-pointer ${activeBadge === badge?.slug ? 'bg-primary text-white' : 'bg-light text-primary'}`}
                                    onClick={() => handleBadgeClick(badge)}
                                >
                                    {badge?.name}
                                </div>
                            ))}
                        </div>
                    )}
                    {/* <div className="card-container d-flex flex-wrap gap-4">
                        {key === "previous-learnings" && <>
                            {joinedClasses?.length > 0 ? joinedClasses?.map((item) => {
                                return (
                                    <div>
                                        <VerticalCard cardContent={item?.class} joined={item?.is_joined} tab={key} certificatStatus={item?.certificate_status} />
                                    </div>
                                );
                            })
                                :
                                <div className="card-container w-100">
                                    <NoData page="my-learning" />
                                </div>
                            }
                        </>
                        }
                        {key === "my-registrations" &&
                            <>
                                {registeredClasses?.length > 0 ? registeredClasses?.map((item) => {
                                    return (
                                        <div>
                                            <VerticalCard cardContent={item?.class} joined={item?.is_joined} tab={key} />
                                        </div>
                                    );
                                }) :
                                    <div className="card-container w-100">
                                        <NoData page="my-learning" />
                                    </div>
                                }
                            </>
                        }
                        {key === "my-playlist" &&
                            <>
                                {bookmarkClasses?.length > 0 ? bookmarkClasses?.map((item) => {
                                    return (
                                        <div>
                                            <VerticalCard cardContent={item?.class} tab={key} />
                                        </div>
                                    );
                                })
                                    :
                                    <div className="card-container w-100">
                                        <NoData page="my-learning" />
                                    </div>
                                }
                            </>
                        }
                    </div> */}

                    <MyLearningPaginate tabKey={key} activeBadge={activeBadge} selectedCategory={selectedCategory} searchQuery={searchClass}/>
                </div>
            </div>
        </Layout>
    );
};

export default MyLearning;
