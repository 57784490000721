import axios from "axios";

/**
    * @function transactionHistoryService
    * @param 
    * @description This function is used to get the list of transaction history
*/
export const transactionHistoryService = async ({perPageItem ='' ,page=''}) => {
    try {
        const result = await axios.get(`user/transaction-history?${perPageItem && "&per_page=" + perPageItem} ${page && "&page=" + page}`);
        return result;
    } catch (error) {
        return error;
    }
};