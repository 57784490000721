
//importing images
import arrow from "../../../assets/images/InterestSelectionPage/arrowIcon.svg";
import whiteBtnArrow from "../../../assets/images/InterestSelectionPage/white-btn-arrow.svg";


//importing styles
import "../../../assets/scss/components/button/buttonWithArrow.scss";
const ButtonWithArrow = ({ name, isLoading, disabled ,type ,onClick }) => {
  return (
    <div className={type==='whitebtn'?'btn-main-container-white': "btn-main-container"}>
      <button
        className={type ==='whitebtn'?"btn-main btn btn bg-white btn-sm rounded w-100 text-primary " : "btn-main btn btn-primary btn-sm rounded w-100 text-left" }
        type="submit"
        disabled={disabled}
        onClick={onClick}
      >
        <div className="d-flex gap-2 justify-content-center">
          {isLoading ? (
            "Loading..."
          ) : (
            <>
              <span className="fw-semibold">{name}</span>
              {type ==='whitebtn'?<img src={whiteBtnArrow} alt="arrow" />:<img src={arrow} alt="arrow" />}
            </>
          )}
        </div>
      </button>
    </div>
  );
};

export default ButtonWithArrow;
