import { useState, useEffect } from "react";
import parse from 'html-react-parser';
import { Container } from "react-bootstrap";

//importing components
import Navbar from "../../components/common/navbar/Navbar";
import Footer from "../../components/common/footer/Footer";

import { getContentService } from "../../services/content.service";

const TermsAndConditions = () => {
    const [content, setContent] = useState()

    const fetchContent = async () => {
        const result = await getContentService("term_condition");
        setContent(result?.data?.configuration?.config_value);
    };

    useEffect(() => {
        fetchContent()
    }, [])

    return (
        <>
            <Navbar />
            <div className="custom-container max-w-home">
                <Container className="mx-4 mt-3">
                    {content && parse(content)}
                </Container>
            </div>
            <Footer />
        </>
    );
}

export default TermsAndConditions;
