import { Modal } from "react-bootstrap";
import Lottie from "lottie-react";
import { Link } from "react-router-dom";

//importing components
import Button from "../common/button/Button";

//importing styles
import "../../assets/scss/components/modal/recordingAlertModal.scss";

//importing animation
import animation from "../../assets/animation/Animation.json";

const PaymentSuccessModal = ({ show, setShow }) => {
    const handleClose = () => setShow(false);

    return (
        <Modal show={show} onHide={handleClose}>
            <div className="py-4 px-3">
                <div className="w-50 mx-auto">
                    <Lottie animationData={animation} loop={true} />
                </div>
                <div className="my-4">
                    <p className="fs-24 text-center text-success mb-1 fw-bold">Your Payment Has Been</p>
                    <p className="fs-24 text-center text-success mb-1 fw-bold">Successful</p>
                </div>
                <Link className="text-decoration-none" to={`/`}>
                    <div
                        className="btn-container text-center"
                        style={{ width: "70px", margin: "auto" }}
                    >
                        <Button name="Home" />
                    </div>
                </Link>
            </div>
        </Modal>
    );
};

export default PaymentSuccessModal;
