import axios from "axios";

/**
    * @function getClassDetail
    * @param (classSlug, id)
    * @description This function is used to class details
*/
export const getClassDetail = async (classSlug, id) => {
    try {
        const result = await axios.get(`user/class-detail/${classSlug}/${id}`);
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function trackClassViewService
    * @param (id)
    * @description This function is used to track class view
*/
export const trackClassViewService = async (id) => {
    try {
        const result = await axios.post(`user/track-class-view/${id}`, {});
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function generateOtpVideoCipherService
    * @param (video_link)
    * @description This function is used to generate otp & playbackInfo for video
*/
export const generateOtpVideoCipherService = async (video_link) => {
    try {
        const result = await axios.post(`user/generate-otp-vdo-cipher/${video_link}`, {});
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function getLanguageByClass
    * @param { classSlug }
    * @description This function is used to get all the languages
*/
export const getLanguageByClass = async ({ classSlug = "" }) => {
    try {
        const result = await axios.get(`admin/class-list-by-languages?${classSlug && "class_type_slug=" + classSlug}`);
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function classRegistrationService
    * @param { classId }
    * @description This function is used to get all the languages
*/
export const classRegistrationService = async ({ classId }) => {
    try {
        const result = await axios.post(`user/register-class`, {
            class_id: classId
        });
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function classRegistrationService
    * @param { classId, class_join_date }
    * @description This function is used to get all the languages
*/
export const classJoinService = async ({ classId, class_join_date }) => {
    try {
        const result = await axios.post(`user/join-class`, {
            class_id: classId,
            class_join_date: class_join_date,
        });
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function rateReviewService
    * @param { classId, rating, review }
    * @description This function is used to get review in class
*/
export const rateReviewService = async ({ classId, rating, review }) => {
    try {
        const result = await axios.post(`user/add-rate-review`, {
            class_id: classId,
            rating: rating,
            review: review
        });
        return result;
    } catch (error) {
        return error;
    }
};


/**
    * @function setBookMarkService
    * @param (class_id)
    * @description This function is used to set bookmark
*/
export const setBookMarkService = async ({class_id}) => {
    try {
        const result = await axios.post(`user/toggle-bookmark`, {
            class_id: class_id
        });
        return result;
    } catch (error) {
        return error;
    }
};


/**
    * @function classDeRegistrationService
    * @param { classId }
    * @description This function is used to UnRegistered the class
*/
export const classDeRegistrationService = async ({ classId }) => {
    try {
        const result = await axios.post(`user/de-register-class`, {
            class_id: classId
        });
        return result;
    } catch (error) {
        return error;
    }
};
