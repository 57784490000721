import Slider from "react-slick";

//importing images
import nextIcon from "../../../assets/images/Slider/nextSliderIcon.svg";
import prevIcon from "../../../assets/images/Slider/prevSliderIcon.svg";

//importing styles
import "../../../assets/scss/components/slider/siteSlider.scss";

const SiteSlider = ({ children, cardType, noOfItems }) => {
  const PrevArrow = ({ onClick }) => {
    return (
      <div
        style={{
          position: "absolute",
          right: "70px",
          bottom: "-43px",
          zIndex: "1",
          cursor: "pointer",
        }}
        className="prevArrow"
        onClick={onClick}
      >
        <img src={prevIcon} alt="PrevIcon" />
      </div>
    );
  };
  const NextArrow = ({ onClick }) => {
    return (
      <div
        style={{
          position: "absolute",
          right: "2px",
          bottom: "-43px",
          zIndex: "1",
          cursor: "pointer",
        }}
        className="nextArrow"
        onClick={onClick}
      >
        <img src={nextIcon} alt="PrevIcon" />
      </div>
    );
  };

  //slider settings

  const settings = {
    dots: cardType === "vertical" ? noOfItems > 3 : noOfItems > 1,
    infinite: cardType === "vertical" ? noOfItems > 3 : noOfItems > 1,
    speed: 500,
    nextArrow:
      cardType === "vertical"
        ? noOfItems > 3 && <NextArrow />
        : noOfItems > 1 && <NextArrow />,
    prevArrow:
      cardType === "vertical"
        ? noOfItems > 3 && <PrevArrow />
        : noOfItems > 1 && <PrevArrow />,
    // slidesToShow: cardType === "vertical" ? (noOfItems > 3 ? noOfItems : 3) : 1,
    slidesToScroll: 1,
    ...((cardType === "vertical" || cardType === "horizental") && {
      variableWidth: true,
    }),
    ...(cardType === "vertical" && {
      responsive: [
        {
          breakpoint: 1220,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            variableWidth: true,
          },
        },
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            variableWidth: true,
          },
        },
        {
          breakpoint: 430,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    }),
  };

  return (
    <div className="siteslider-main-container">
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

export default SiteSlider;
