import React from 'react';
import { Link } from 'react-router-dom';
import cover from "../../../../assets/images/CoachReview/cover.png";
import { Rating } from "react-simple-star-rating";
import ratingIcon from "../../../../assets/images/Rating/ratingIcon.svg";


const CoachCourceHistoryCard = (props) => {
    console.log(props,'props')
    return (
        <div className=" shadow-sm rounded   cursor-pointer ">
            <div className="d-flex justify-content-between align-items-center mt-3 ">
                <div className="row g-0">
                    <div className="col-xl-5 p-1">
                        <img
                            src={props?.item?.thumb_image}
                            alt="profile"
                            className="img-fluid rounded"
                            style={{height:"150px" ,width:"500px"}}
                        />
                    </div>
                    <div className="col-xl-7">
                        <div>
                            {/* <div className="d-flex gap-2 ">
                                <div className="cource-text fw-semibold">
                                    {props?.item?.title}
                                </div>
                            </div> */}
                            <div className="d-flex">
                                <Link className="mb-0" to={`/class/${props?.item?.slug}`}>
                                    {props?.item?.title}
                                </Link>
                            </div>
                            <div className="d-flex">
                                <Rating
                                    initialValue={props?.item?.average_rating?.toFixed(2)}
                                    value={props?.item?.average_rating?.toFixed(2)}
                                    customIcons={ratingIcon}
                                    readonly={true}
                                    size={20}
                                    allowFraction={true}
                                />
                                <div className="p-1 text-secondary">{props?.item?.average_rating?.toFixed(2)}/5</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CoachCourceHistoryCard;