import { createSlice } from "@reduxjs/toolkit";
import { getToken } from "../utils/Auth.Service";

const initialState = {
    userDetail: null,
    countryList: null,
    loggedIn: false,
    token: getToken()?.tokenAccess
};

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUserDetail: (state, action) => {
            state.userDetail = action.payload;
        },
        setCountryList: (state, action) => {
            state.countryList = action.payload;
        },
        setLoggedIn: (state, action) => {
            state.loggedIn = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        }
    }
});
export const {
    setUserDetail,
    setCountryList,
    setLoggedIn,
    setToken
} = userSlice.actions;

export const selectUserDetails = (state) => state.user.userDetail;
export const selectLoggedIn = (state) => state.user.loggedIn;
export const selectToken = (state) => state.user.token;
export const selectCountryList = (state) => state.user.countryList;

export default userSlice.reducer;
