//importing styles
import "../../../assets/scss/components/input/inputWithPhoneCode.scss";

const InputWithPhoneCode = ({ name, phoneCode, onChange, errorClass }) => {
  return (
    <>
      {phoneCode ? (
        <div className="inputWithPhoneCode-container">
          <p
            className={`${errorClass && "err-border"
              } input-PhoneCode text-secondary`}
          >
            {phoneCode ? phoneCode : "00"}
          </p>
          <input
            name={name}
            type="text"
            className={`${errorClass && "error-border"} form-control`}
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            placeholder="Enter phone number"
            onChange={onChange}
          />
        </div>
      ) : (
        <input
          name={name}
          type="text"
          className={`${errorClass && "error-border"} form-control`}
          aria-label="Default"
          aria-describedby="inputGroup-sizing-default"
          placeholder="Enter phone number"
          onChange={onChange}
        />
      )}
    </>
  );
};

export default InputWithPhoneCode;
