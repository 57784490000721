import axios from "axios";

/**
    * @function generateCertificateService
    * @param { class_id }
    * @description This function is used to generate certificate
*/
export const generateCertificateService = async ({ class_id }) => {
    try {
        const result = await axios.post(`user/generate-certificate`, {
            class_id: class_id
        });
        return result;
    } catch (error) {
        return error;
    }
}

/**
    * @function verifyCertificateService
    * @param { class_id }
    * @description This function is used to verify certificate
*/
export const verifyCertificateService = async ({ serial_number }) => {
    try {
        const result = await axios.get(`user/verify-certificate?serial_number=${serial_number}`);
        return result;
    } catch (error) {
        return error;
    }
}



/**
    * @function getCertificateService
    * @param { class_id }
    * @description This function is used to get certificate List
*/
export const getCertificateService = async ({class_type ,page='' ,perPageItem = "8" ,searchQuery =''}) => {
    try {
        const result = await axios.get(`user/user-certificates?${class_type && "&class_type=" + class_type}${page && "&page=" + page}${perPageItem && "&per_page=" + perPageItem}${searchQuery && "&searchQuery=" + searchQuery}`);
        return result;
    } catch (error) {
        return error;
    }
}


