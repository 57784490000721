import axios from "axios";

/**
    * @function updateUserProfileService
    * @param { first_name, last_name, email, gender, dob, phone_number, country_id, news_letter_subscription, profession, category_ids, image }
    * @description This function is used to update the user's profile
*/
export const updateUserProfile = async ({ first_name, last_name, email, gender, dob, phone_number, country_id, news_letter_subscription, profession, category_ids, image }) => {
    try {
        const result = await axios.post(`user/update-profile`, {
            first_name,
            last_name,
            email,
            gender,
            dob,
            phone_number,
            country_id,
            profession,
            news_letter_subscription,
            category_ids,
            image
        }, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        });
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function getUserProfile
    * @param
    * @description This function is used to get the user's profile
*/
export const getUserProfile = async () => {
    try {
        const result = await axios.get(`user/profile`);
        return result;
    } catch (error) {
        return error;
    }
};

/**
    * @function changePasswordService
    * @param data
    * @description This function is used to change password
*/
export const changePasswordService = async (data) => {
    try {
        const result = await axios.post(`user/change-password`, data);
        return result;
    } catch (error) {
        return error;
    }
};


/**
    * @function getUserStats
    * @param
    * @description This function is used to get the user's Stats like class attended , total certificate and hour spand
*/
export const getUserStats = async () => {
    try {
        const result = await axios.get(`user/stats`);
        return result;
    } catch (error) {
        return error;
    }
};