//importing styles
import "../../../assets/scss/components/dropdown/dropdown.scss";

const Dropdown = ({ options, setSelectedCountry, defaultOption }) => {
  /**
   * @function dropdownChangeHandler
   * @param
   * @description This function is used to get the selected value of the dropdown
   */
  const dropdownChangeHandler = (value) => {
    setSelectedCountry(value);
  };
  return (
    <div className="dropdown-main-container">
      <select
        className="dropdown-itemMenu form-control"
        onChange={(e) => dropdownChangeHandler(e.target.value)}
      >
        <option className="dropdown-items" disabled selected>
          Select a country
        </option>

        {options?.map((item, index) => {
          return (
            <option
              key={index}
              className="dropdown-items"
              value={item?.id}
              selected={item?.id === defaultOption}
            >
              {item.name}
            </option>
          );
        })}
      </select>
      {/* <div className="dropdown_icon_container">
        <img src={dropdownIcon} alt="icon" />
      </div> */}
    </div>
  );
};

export default Dropdown;
