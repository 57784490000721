//importing images
import siteLogo from "../../../assets/images/Footer/new-white-logo.png";
import Facebook from "../../../assets/images/Footer/Facebook.svg";
import Instagram from "../../../assets/images/Footer/Instagram.svg";
import Linkedin from "../../../assets/images/Footer/Linkedin.svg";
import Tiltok from "../../../assets/images/Footer/Tiltok.svg";
import Twitterr from "../../../assets/images/Footer/Twitterr.svg";
import Youtube from "../../../assets/images/Footer/Youtube.svg";


//importing styles
import "../../../assets/scss/components/footer/footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="footer-main">
            {/* upward content */}
            <div className="footer-top-main">
                <div className="footer-links-logo-container">
                    <img src={siteLogo} alt="siteLogo" style={{ marginBottom: "1rem" }} />
                </div>
                <div className="footer-links">
                    <h6>Explore ReSkills</h6>
                    <Link className="text-decoration-none" to="/classes/onlive">
                        <p>OnLive</p>
                    </Link>
                    <Link className="text-decoration-none" to="/classes/onthego">
                        <p>OnTheGo</p>
                    </Link>
                    <Link className="text-decoration-none" to="/classes/onstage">
                        <p>OnStage</p>
                    </Link>
                    <Link className="text-decoration-none" to="/classes/onference">
                        <p>OnFerence</p>
                    </Link>
                    {/* <Link className="text-decoration-none" to="https://about.reskills.com/1-on-1" target="_blank">
                        <p>1 on 1</p>
                    </Link> */}
                    {/* <Link className="text-decoration-none" to="/classes/special">
                        <p>Special</p>
                    </Link> */}
                    <Link className="text-decoration-none" to="/explore-all-coaches">
                        <p>ReSkills Coaches</p>
                    </Link>
                </div>
                <div className="footer-links">
                    <h6>The Company</h6>
                    <Link className="text-decoration-none" to="https://about.reskills.com/">
                        <p>About ReSkills</p>
                    </Link>
                    <Link className="text-decoration-none" to="https://about.reskills.com/micro-certification/">
                        <p>Global Accreditation</p>
                    </Link>
                    <Link className="text-decoration-none" to="https://about.reskills.com/blog-posts/">
                        <p>News/Update</p>
                    </Link>
                    <Link className="text-decoration-none" to="/terms">
                        <p>Terms and Conditions</p>
                    </Link>
                    <Link className="text-decoration-none" to="/privacy">
                        <p>Privacy Policy</p>
                    </Link>
                </div>
                <div className="footer-links">
                    <Link className="text-decoration-none" to="/privacy">
                        <h6>Need Help?</h6>
                    </Link>
                    <Link className="text-decoration-none" to="https://about.reskills.com/contact-reskills/">
                        <p>Contact Us</p>
                    </Link>
                    <Link className="text-decoration-none" to="https://about.reskills.com/ReSkills%20Knowledge%20Base/">
                        <p>FAQS</p>
                    </Link>
                    <Link className="text-decoration-none" to="/certificate/verify">
                        <p>Certificate Authenticator</p>
                    </Link>

                    <Link className="text-decoration-none" to="https://reskillscoachdirectory.com/new-coach-profile/">
                        <p>ReSkills Coach Directory</p>
                    </Link>
                </div>

                <div className="footer-links margin-small">
                    {/* <Link className="text-decoration-none" to="https://about.reskills.com/reskills-career-partners/"> */}
                        <h6>Our Partners</h6>
                   
                   
                    <Link className="text-decoration-none"  to="https://about.reskills.com/reskills-career-partners/">
                        <p>Career</p>
                    </Link>
                    <div className="big-footer-items">
                    <h6>Redemption</h6>
                    <Link className="text-decoration-none" to="/redeem">
                        <p>Redeem Gift Card</p>
                    </Link>
                    </div>
                    
                </div>
                <div className="footer-links small-footer-items">
                    <h6>Redemption</h6>
                    <Link className="text-decoration-none" to="/redeem">
                        <p>Redeem Gift Card</p>
                    </Link>
                </div>
            </div>
            {/* separation Line */}
            <hr className="separator-line" />
            {/* bottom content */}
            <div className="footer-bottom-main">
                <div>
                    <span className="footer-bottom-text fw-semibold">
                        © ReSkills 2021-2024
                    </span>
                </div>
                <div className="footer-social-handles-main">
                    <Link className="text-decoration-none" to="https://www.facebook.com/reskillsedtech/" target="_blank">
                        <img src={Facebook} alt="Facebook" />
                    </Link>
                    <Link className="text-decoration-none" to="https://www.instagram.com/reskills_global/?hl=en" target="_blank">
                        <img src={Instagram} alt="Instagram" />
                    </Link>
                    <Link className="text-decoration-none" to="https://my.linkedin.com/company/reskills-global" target="_blank">
                        <img src={Linkedin} alt="Linkedin" />
                    </Link>
                    <Link className="text-decoration-none" to="https://www.tiktok.com/@reskills" target="_blank">
                        <img src={Tiltok} alt="Tiltok" />
                    </Link>
                    <Link className="text-decoration-none" to="https://twitter.com/ReSkills_" target="_blank">
                        <img src={Twitterr} alt="Twitter" />
                    </Link>
                    <Link className="text-decoration-none" to="https://www.youtube.com/channel/UCGtlCDq8R3tO9HQKW4Dr8Wg" target="_blank">
                        <img src={Youtube} alt="Youtube" />
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Footer;