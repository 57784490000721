
import { useState } from 'react';
import { Modal, Form, InputGroup, Toast } from 'react-bootstrap';
import { FacebookShareButton, FacebookIcon, LinkedinShareButton, LinkedinIcon, RedditShareButton, RedditIcon, TumblrShareButton, TumblrIcon, PinterestShareButton, PinterestIcon, VKShareButton, VKIcon, TelegramShareButton, TelegramIcon, LineShareButton, LineIcon, WhatsappShareButton, WhatsappIcon } from "react-share";

const ShareModal = (props) => {
    const [copyToast, setCopyToast] = useState(false);

    /**
        * @function handleCopyToast
        * @params
        * @description toggle the copy toast visibilty
    */
    const handleCopyToast = () => {
        if (!copyToast) {
            setCopyToast(!copyToast);
        }
    };

    /**
        * @function handleModalClose
        * @params
        * @description close share modal
    */
    const handleModalClose = () => {
        props.handleClose()
        setCopyToast(false)
    }

    return <Modal show={props.show} onHide={handleModalClose} size={props.size} centered>
        <Modal.Header closeButton>
            <Modal.Title className='fw-600'>Share a link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='d-flex justify-content-center mb-3 flex-wrap gap-3'>
                <FacebookShareButton url={props?.shareUrl}>
                    <FacebookIcon className='me-2' size={48} round />
                </FacebookShareButton>
                <LinkedinShareButton url={props?.shareUrl}>
                    <LinkedinIcon className='me-2' size={48} round />
                </LinkedinShareButton>
                <RedditShareButton url={props?.shareUrl}>
                    <RedditIcon className='me-2' size={48} round />
                </RedditShareButton>
                <TumblrShareButton url={props?.shareUrl}>
                    <TumblrIcon className='me-2' size={48} round />
                </TumblrShareButton>
                <PinterestShareButton url={props?.shareUrl}>
                    <PinterestIcon className='me-2' size={48} round />
                </PinterestShareButton>
                <VKShareButton url={props?.shareUrl}>
                    <VKIcon className='me-2' size={48} round />
                </VKShareButton>
                <TelegramShareButton url={props?.shareUrl}>
                    <TelegramIcon className='me-2' size={48} round />
                </TelegramShareButton>
                <LineShareButton url={props?.shareUrl}>
                    <LineIcon className='me-2' size={48} round />
                </LineShareButton>
                <WhatsappShareButton url={props?.shareUrl}>
                    <WhatsappIcon className='me-2' size={48} round />
                </WhatsappShareButton>
            </div>
            <InputGroup className="border rounded">
                <Form.Control
                    type="text"
                    className='border-0'
                    value={props?.shareUrl}
                />
                <InputGroup.Text className='border-0 bg-transparent'>
                    <span role='button' onClick={() => {
                        navigator.clipboard.writeText(`${props?.shareUrl}`)
                        handleCopyToast()
                    }}>Copy</span>
                </InputGroup.Text>
            </InputGroup>

            {/* Copy Toast */}
            <Toast className='w-100 bg-success text-light my-3' show={copyToast} onClose={handleCopyToast}>
                <Toast.Body>Copied to Clipboard!</Toast.Body>
            </Toast>
        </Modal.Body>
    </Modal>;
};

export default ShareModal;
