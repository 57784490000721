import React, { useEffect, useState } from 'react';
import Home from '../../assets/images/Sidebar/Home.png'
import Navbar from '../../components/common/navbar/Navbar';
import Footer from '../../components/common/footer/Footer';
import FeaturedCoachCard from '../../components/common/cards/FeaturedCoachCard/FeaturedCoachCard';
import { getFeaturedCoaches } from '../../services/coach.service';
import Paginate from '../../components/common/paginate/Paginate';
import { useDispatch } from 'react-redux';
// Style
import '../../assets/scss/pages/allCoaches/allCoaches.scss'
// import { setCoach } from '../../redux/coachSlice';
import { useSelector } from 'react-redux';
import { selectCoachDetail ,setCoach } from '../../redux/coachSlice';
import CoachPaginate from '../../components/common/paginate/CoachPaginate';

const AllCoaches = () => {
    const [featuredCoaches, setFeaturedCoaches] = useState();
    const [totalItem, setTotalItem] = useState()
    const dispatch = useDispatch();


    // const fetchFeaturedCoaches = async () => {
    //     const result = await getFeaturedCoaches({
    //         perPageItem:12
    //       });
    //     //   dispatch(setCoach(result))
    //       setFeaturedCoaches(result?.data?.coaches?.data);
    // };
    // useEffect(() => {
        
    //     fetchFeaturedCoaches();
    // }, []);

    // const coachStatus = featuredCoaches?.map((item, index) => item)
    // const coach = useSelector(selectCoachDetail) 
    
    return (
        <div >
            <Navbar />
            <div className='custom-container max-w-home'>
                <div className='mx-5 main-coach-container '>
                    <div className='my-4'>
                        <div className='d-flex mb-3'>
                            <img src={Home} alt='' style={{ height: "15px", width: "15px" }} className='m-2' />
                            <div className='py-1 px-2 font-mute'>/</div>
                            <div className='m-1 my-account px-2 rounded fw-semibold '><small >Coaches</small></div>
                        </div>
                        <div className="d-flex align-items-center  mb-md-0 me-md-auto  text-decoration-none">
                            <h4 className="fs-5 d-none d-sm-inline fw-bold px-2 min-width-0">Coaches</h4>
                        </div>
                    </div>

                    <div className=' w-100'>
                        {/* {coachStatus?.map((item, index) => {
                            return (
                                <div className=''>
                                    {" "}
                                    <FeaturedCoachCard key={index} cardDetail={item} />{" "}
                                </div>
                            );
                        })} */}

                        <CoachPaginate/>
                    </div>

                    {/* <Paginate totalItem={featuredCoaches?.length} /> */}
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default AllCoaches;