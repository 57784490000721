import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bannerList: [],
};
const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    setBannerList: (state, action) => {
      state.bannerList = action.payload;
    },
    
  },
});
export const { setBannerList} = bannerSlice.actions;

export const selectBannerList = (state) => state.banner.bannerList;

export default bannerSlice.reducer;