import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  coachDetail: "",
};
const CoachSlice = createSlice({
  name: "coachList",
  initialState,
  reducers: {
    setCoach: (state, action) => {
      state.coachDetail = action.payload;
    },
    
  },
});
export const { setCoach} = CoachSlice.actions;

export const selectCoachDetail = (state) => state.coachList.coachDetail;

export default CoachSlice.reducer;